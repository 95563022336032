import * as React from 'react';
import { inject } from 'mobx-react';
import { Button, Input, InputNumber, Layout, Select, Tabs, Tooltip } from 'antd'; 
import { RulesStores } from '../stores';
import { STORE_RULES_LIST, STORE_RULE_EDIT, STORE_TAGS_GROUP } from '../constants';
import { observer } from 'mobx-react-lite';
import RuleSaveAsDialog from './RuleSaveAsDialog';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import PipelineEditor from '../../pipeline_base/components/PipelineEditor';
import { RuleEditSettingsDialog, RuleLabelsSelect, RulePreviewForm, RuleTagsDrawer } from '.';
import { InferenceModelsService } from '../services';
import { reaction } from 'mobx';
import { InferenceRule, NamedEntitiesRecognitionRule } from '../models';
import { INFERENCE_MODEL_TYPES } from './ParametersModelEditor';
import { NRE_MODEL_TYPE } from './ParametersNERModelEditor';
import { Prompt } from 'react-router';
const { Content } = Layout;

const { TabPane } = Tabs;

type Props = RulesStores;

export const RuleEditPage: React.FC<Props> = ({RuleEditUI, TagsGroupUI, RulesListUI}) => {  
    const blockTypes = [
        { value: 'LINE_BLOCK', name: 'LINE' },
        { value: 'TEXTBOX_BLOCK', name: 'TEXTBOX' },
        { value: 'TABLE_BLOCK', name: 'TABLE' },
        { value: 'CELL_BLOCK', name: 'CELL' },
        { value: 'HORIZONTAL_LINE_BLOCK', name: 'HORIZONTAL' },
        { value: 'CLUSTER_BLOCK', name: 'CLUSTER' },
        { value: 'HORIZONTAL_MULTILINE_BLOCK', name: 'HORIZONTAL_MULTILINE' },
        { value: 'PARAGRAPH_BLOCK', name: 'PARAGRAPH' },
        { value: 'PAGE_BLOCK', name: 'PAGE' },
        { value: 'WORD_BLOCK', name: 'WORD' },
        { value: 'DOCUMENT_TABLE_BLOCK', name: 'DOCUMENT_TABLE' },
        { value: 'FORM_ITEM_BLOCK', name: 'FORM_ITEM' },
        { value: 'SMART_BLOCK', name: 'SMART' }
    ];

    const [isValidationFailed, setIsValidationFailed] = React.useState(false);
    const [editableRuleFields, setEditableRuleFields] = React.useState<{name: string; value: string}[]>([]);
    const [disabledRuleFields, setDisabledRuleFields] = React.useState<string[]>([]);
    const [selectedModelId, setSelectedModelId] = React.useState<string | undefined>(undefined);
    const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);
    const isMounted = React.useRef(true);

    const store = RuleEditUI!;
    React.useEffect(() => {
        store.setCurrentTabsKey('1');
        store.setIsRuleEdited(false);

        if (store.editableRule) {
            getTypeSpecificFieldValues();
        } else {
            reaction(() => store.editableRule, (rule, r) => {
                if (rule) {
                    getTypeSpecificFieldValues();
                    r.dispose();
                }
            });
        }

        return (() => {
            isMounted.current = false;
            store.resetError();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (store.currentProject) {
            store.loadModels();
            RulesListUI?.getPinnedRules();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, store.currentProject]);

    React.useEffect(() => {
        function beforeUnload(e: BeforeUnloadEvent) {
            if (!store.isRuleEdited) {
                return;
            }
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [store.isRuleEdited]);

    const handleSubmit = async() => {
        const errors = store.editableRule?.validateState();
        if (!errors?.length) { 
            await store.submitPipelineForms();
            if (!store.isValidationFailed() && isMounted.current) {
                const savedRuleId = await store.save(undefined, true);
                setIsValidationFailed(false);
                if (savedRuleId != null) {
                    store.clearPreselecedData();
                }
            }
        } else {
            setIsValidationFailed(true);
        }
    };

    const handleCancel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        store.clearPreselecedData();  
        store.cancel(true);
        store.setCurrentGroupId({});
    };

    const getHeaderButtons = () => {
        let buttons = [ 
            <Button key="cancel" size="large" className="light" htmlType="button" onClick={handleCancel}>Cancel</Button>,
            <Button key="save-as" className="light" size="large" onClick={openSaveAsDialog}>Save as...</Button>,
            <Button loading={store.isLoading} key="submit" type="primary" size="large" onClick={handleSubmit}>Save changes</Button>];

        return buttons;
    };

    const openSaveAsDialog = async () => {
        const errors = store.editableRule?.validateState();
        if (!errors?.length) { 
            await store.submitPipelineForms();
            if (!store.isValidationFailed() && isMounted.current) {
                store.setSaveAsDialogVisible(true);
                setIsValidationFailed(false);
            } 
        } else {
            setIsValidationFailed(true);
        }

    };

    const getTypeSpecificFieldValues = () => {
        if (!store.editableRule || isMounted.current !== true) {
            return;
        }

        setEditableRuleFields([]);
        setDisabledRuleFields([]);

        const generalFields = [
            'ruleType', 'name', 'tag', 'priority', 
            'description', 'overridePriority', 'groupId',
            'tagId', 'id', 'projectId', 'updateDate', 'state', 
            'status', 'pipeline', 'excludedBlockTypes', 'errors'
        ];

        const hiddenFields: string[] = [];

        const ruleFields = Object.entries(store.editableRule).map(([key, value]) => ({ name: key, value }));

        if (store.editableRule.ruleType === 'InferenceRule') {
            const rule = store.editableRule as InferenceRule;
            setSelectedLabels(rule.labels);
            setSelectedModelId(rule.modelId);
        }

        if (store.editableRule.ruleType === 'NamedEntitiesRecognitionRule') {
            const rule = store.editableRule as NamedEntitiesRecognitionRule;
            setSelectedLabels(rule.entities);
            setSelectedModelId(rule.modelId!);
        }

        if (store.editableRule.ruleType === 'SmartIndexRule') {
            hiddenFields.push('instructWorkflowId');
            setDisabledRuleFields(['outputSchemeName']);
        }

        if (store.editableRule.ruleType === 'ElasticSearchRegexpQueryRule') {
            hiddenFields.push('flags');
            hiddenFields.push('caseInsensitive');
        }

        if (store.editableRule.ruleType === 'ElasticSearchFuzzyQueryRule') {
            hiddenFields.push('fuzziness');
            hiddenFields.push('prefixLength');
            hiddenFields.push('transpositions');
        }

        if (store.editableRule.ruleType === 'ElasticSearchWildcardQueryRule') {
            hiddenFields.push('caseInsensitive');
        }

        setEditableRuleFields(ruleFields.filter(x => !generalFields.includes(x.name) && !hiddenFields.includes(x.name)));     
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>, name: string) => {
        if (!store.editableRule) {
            return;
        }

        const { value } = e.target;
        store.applyFields([{ name: [name], value }]);
        setIsValidationFailed(false);
    };

    const handleOnEnterPress = (e: React.KeyboardEvent<HTMLInputElement>, name: string) => {
        if (e.key === 'Enter' && store.editableRule) {
            const { value } = e.target as HTMLInputElement;
            store.applyFields([{ name: [name], value }]);
            setIsValidationFailed(false);
        }
    };

    const handleSelect = (value: string | string[], name: string) => {
        if (store.editableRule) {
            store.applyFields([{ name: [name], value }]);
            setIsValidationFailed(false);
        }
    };

    const handleModelSelect = (value: string, name: string) => {
        handleSelect(value, name);
        setSelectedModelId(value);
    };

    const handleLabelsSelect = (value: string[], name: string) => {
        handleSelect(value, name);
        setSelectedLabels(value);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getSettingDisplayValue = (name: string, value: any) => {
        switch (name) {
        case 'modelId': {
            const mlModel = store.mlModels.find(x => x.id === value);
            const ruleType = store.editableRule?.ruleType;

            const modelTypes = ruleType === 'InferenceRule' ? INFERENCE_MODEL_TYPES : NRE_MODEL_TYPE;

            return (
                <Select 
                    style={{width: 200}}
                    onChange={(selectValue) => handleModelSelect(selectValue, name)}
                    showSearch
                    defaultValue={mlModel ? mlModel.name : value}
                    options={store.mlModels.slice().filter(m => modelTypes.includes(m.type)).map(x => ({ label: x.name, title: x.name, value: x.id }))}
                    filterOption={(input, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            );
        }
        case 'connection':
            return (
                <Select 
                    style={{width: 200}}
                    showSearch
                    onChange={(selectValue) => handleSelect(selectValue, name)}
                    defaultValue={value?.name}
                    options={store.connections?.map(x => ({ label: x.name, title: x.name, value: x.name })) ?? []}
                    filterOption={(input, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            );
        case 'operator': {
            const operators = ['Or', 'And'];
            return (
                <Select
                    onChange={(selectValue) => handleSelect(selectValue, name)}
                    style={{width: 90}}
                    defaultValue={value}
                    options={operators.map(x => ({ label: x, title: x, value: x })) ?? []}
                />
            );
        }
        case 'threshold':
        case 'minimumPercentageShouldMatch':
            return <InputNumber min={0} max={100} defaultValue={value} onBlur={(e) => handleOnBlur(e, name)} onKeyPress={(e) => handleOnEnterPress(e, name)}/>;
        case 'slope':
            return <InputNumber defaultValue={value} onBlur={(e) => handleOnBlur(e, name)} onKeyPress={(e) => handleOnEnterPress(e, name)}/>;
        
        case 'blockType': {
            return (
                <Select
                    style={{width: 200}}
                    onChange={(selectValue) => handleSelect(selectValue, name)}
                    defaultValue={value}
                    options={blockTypes.map(x => ({ label: x.name, title: x.name, value: x.value })) ?? []}
                    filterOption={(input, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            );
        }
        case 'excludedBlockTypes': {
            return (
                <Select
                    mode="multiple"
                    style={{width: 200}}
                    onChange={(selectValue) => handleSelect(selectValue, name)}
                    defaultValue={value}
                    options={blockTypes.map(x => ({ label: x.name, title: x.name, value: x.value })) ?? []}
                    filterOption={(input, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            );
        }
        case 'entities': 
        case 'labels': {
            const service = new InferenceModelsService();
            return (
                <RuleLabelsSelect 
                    service={service} 
                    dataId="rule-visible-label-select"
                    selectedModelId={selectedModelId} 
                    onChange={(selectValue) => handleLabelsSelect(selectValue, name)} 
                    value={selectedLabels}
                />
                
            );
        }
        case 'query':
            return <Input style={{minWidth: 300}} defaultValue={value} onBlur={(e) => handleOnBlur(e, name)} onKeyPress={(e) => handleOnEnterPress(e, name)}/>;
        default:
            return (
                <Input
                    defaultValue={value}
                    disabled={disabledRuleFields.includes(name)}
                    onBlur={e => handleOnBlur(e, name)}
                    onKeyPress={e => handleOnEnterPress(e, name)}
                />
            );
        }
    };

    const popOutPreview = () => {
        if (!store.currentProject?.id || !store.editableRule?.id) {
            return;
        }

        store.setIsPreviewPoppedOut(true);
        store.applyFields([]);

        const url = `${window.location.origin}/projects/${store.currentProject.id}/rules/preview/${store.editableRule?.id}/`;
        const previewWindow = window.open(url, 'preview-window', 'height=768,width=1024');
        if (previewWindow) {
            store.setCurrentTabsKey('1');
            previewWindow.focus();
            // Interval is needed due to redirections happening in a new window and onbeforeunload is triggered immediately.
            const timer = setInterval(function() {
                if(previewWindow.closed) {
                    store.setIsPreviewPoppedOut(false);
                    clearInterval(timer);
                }
            }, 500);
        }
    };

    const previewTabHeader = () => {
        return (
            <Tooltip title="Pop out preview">
                <Button 
                    type="text" 
                    icon={<i className="alpha-icon md pop-out-icon" style={{verticalAlign: 'text-top'}} />} 
                    onClick={popOutPreview} 
                />
            </Tooltip>
        );
    };

    const isFieldRequired = (name: string) => {
        const optionalFields = ['pageRange', 'outputSchemeName'];
        if (store.editableRule) {
            return !optionalFields.includes(name);
        }
        return false;
    };

    return (
        <Layout className="screen-size" style={{...{height: '100%', background: 'white'}}}>
            <Prompt
                when={store.isRuleEdited}
                message={'You have unsaved changes. Are you sure you want to leave?'}
            />
            <LayoutHeader  
                subtitle={Utils.getSubtitle(store!.currentProject)}
                title={store.editableRule?.name || 'Edit rule'}
                helpMessage="  "
                buttons={getHeaderButtons()}
            />
            <Layout>
                <Content style={{position: 'relative'}}>
                    <RuleSaveAsDialog store={store} onSaveCallback={() => store.clearPreselecedData()} />
                    <Button 
                        className="tags-menu-trigger" 
                        type="ghost" 
                        icon={<i className="alpha-icon lg side-menu-icon" style={{verticalAlign: 'bottom'}} />}
                        onClick={() => store.setIsDrawerOpen(true)}
                    >
                        Other tags
                    </Button>
                    <RuleTagsDrawer 
                        open={store.isDrawerOpen} 
                        setOpen={store.setIsDrawerOpen} 
                        groupsStore={TagsGroupUI!} 
                        rulesListStore={RulesListUI!} 
                        editableRule={store.editableRule}
                    />
                    <Tabs 
                        activeKey={store.currentTabsKey} 
                        className="rule-edit-tab" 
                        onChange={store!.handleTablsChange} 
                        animated={false}
                        tabBarExtraContent={previewTabHeader()}
                        
                    >
                        <TabPane style={{height: store.currentTabsKey === '1' ? '100%' : 0}} tab="Rule steps" key="1">
                            <div className="validation-message" style={{display: isValidationFailed ? 'block' : 'none'}}>
                                <div>
                                    <i className="alpha-icon md rule-problem-icon"/>
                                    <span>Please check the general settings and fill all mandatory fields correctly</span>
                                </div>
                            </div>
                            <RuleEditSettingsDialog RuleEditUI={store} saveHandler={getTypeSpecificFieldValues} />
                            <div className="rule-settings-wrapper">
                                {editableRuleFields.map((field) => (
                                    <div className="rule-setting" key={field.name}>
                                        <div className={`setting-label ${isFieldRequired(field.name) ? 'required' : null}`}>{Utils.humanizeString(field.name)}:</div>
                                        <div className="setting-value">{getSettingDisplayValue(field.name, field.value)}</div>
                                    </div>
                                ))}
                                <Button 
                                    data-testid="edit-rule-settings" 
                                    data-id="edit-rule-settings" 
                                    className="rule-setting button" 
                                    type="ghost" 
                                    onClick={() => store.setIsEditSettingsDialogVisible(true)}
                                >
                                    All settings
                                </Button>
                            </div>
                            
                            {store.pipelineStore ? <PipelineEditor store={store.pipelineStore!} /> : null}
                        </TabPane>
                        <TabPane 
                            style={{height: store.currentTabsKey === '2' ? '100%' : 0 }} 
                            tab="Preview"
                            key="2" 
                            disabled={!store!.allowPreview || store!.isPreviewPoppedOut}
                        >
                            {store.previewVisualStore ? (
                                <RulePreviewForm
                                    RulePreviewUI={store.previewVisualStore}
                                    project={store.currentProject}
                                />
                            ) : (
                                <span />
                            )}
                        </TabPane>
                    </Tabs>
                </Content>
            </Layout>
        </Layout>
    );
};

export default inject(STORE_RULE_EDIT, STORE_TAGS_GROUP, STORE_RULES_LIST)(observer(RuleEditPage));