/* eslint-disable max-len */
import * as React from 'react';
import { Observer, observer } from 'mobx-react';
import ProjectPackageFilter from './ProjectPackageFilter';
import { Layout, Empty, Tag, Button, Spin, Input, Modal, Row, Col, Pagination, Popover, Tooltip } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import PreviewContent from '../../common/components/PreviewContent';
import { RulePreviewVisualStore } from '../stores';
import { STORE_RULES_PREVIEW } from '../constants';
import { addNewLinesInCopyBuffer, removeAddNewLinesInCopyBuffer } from '../../common/Utils';
import _ from 'lodash';
import { reaction } from 'mobx';
import { SearchData, TreeItem } from 'react-sortable-tree-patch-react-17';
import { PackageLine, Project } from '../../common/models';
import { PackageTag, PreviewIndexToken } from '../types';
import SplitterLayout from 'react-splitter-layout';
import { SearchOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons'; // , LeftOutlined, RightOutlined
import TableBlockPreview from './TableBlockPreview';
import { Subscription } from 'rxjs';
import ReactJson from 'react-json-view';
import { Utils } from '../../common/services/Utils';
// Temporary solution for react-sortable-tree to work with react 17. Waiting for fix in new release.
const SortableTree = require('react-sortable-tree-patch-react-17').default;
const { Content, Sider } = Layout;

type Props = { [STORE_RULES_PREVIEW]: RulePreviewVisualStore } & {
    style?: React.CSSProperties;
    project?: Project | null
};

class RulePreviewForm extends React.Component<Props, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // marker: object[], // like this
    // not {} | undefined because getting error in handleResize() which says that object is possibly undefined even after strict check
    selectedRowData: { rowId: number | undefined; pkgId: string | undefined };
    selectedRowContent: string | JSX.Element | undefined;
    showRowPreviewDialog: boolean;
    treeData: TreeItem[];
    expandedNodes: string[];
    collapsedNodes: string[];
    searchFocusIndex: number | undefined;
    searchFoundCount: number;
    dialogWidth: string | number;
    selectedRowType: string
}>  {      
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state = { 
        selectedRowData: {rowId: undefined, pkgId: undefined}, 
        selectedRowContent: undefined, 
        showRowPreviewDialog: false, 
        treeData: [] as TreeItem[],
        expandedNodes: [],
        collapsedNodes: [],
        searchFocusIndex: undefined,
        searchFoundCount: 0,
        dialogWidth: 768,
        selectedRowType: ''
    };
    resizeHandler: () => void;

    highlightBlockSub: Subscription;

    constructor(props: Readonly<Props>) {
        super(props);
        this.getResult = this.getResult.bind(this);    
        this.resizeHandler = _.debounce(this.handleResize.bind(this), 100);
        this.checkIfNodeIsExpanded = this.checkIfNodeIsExpanded.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.selectNextMatch = this.selectNextMatch.bind(this);
        this.selectPrevMatch = this.selectPrevMatch.bind(this);
        this.searchNode = this.searchNode.bind(this);
        this.searchCallback = this.searchCallback.bind(this);
        this.onSplitterDragEnd = this.onSplitterDragEnd.bind(this);
        this.onPageWithBlockLoadHandler = this.onPageWithBlockLoadHandler.bind(this);
        this.handleFirstPaneResize = this.handleFirstPaneResize.bind(this);
        this.handlesecondPaneResize = this.handlesecondPaneResize.bind(this);
        this.getExpandedChildNodes = this.getExpandedChildNodes.bind(this);

    }

    componentDidMount() {
        addNewLinesInCopyBuffer();
        window.addEventListener('resize', this.resizeHandler);
        const store = this.props.RulePreviewUI!;
        this.highlightBlockSub = store.highlightBlockRedrawSubject.subscribe(this.onPageWithBlockLoadHandler);
    }

    componentWillUnmount() {
        this.setState({treeData: [], expandedNodes: [], collapsedNodes: [], showRowPreviewDialog: false, selectedRowContent: undefined,  selectedRowData: {rowId: undefined, pkgId: undefined}});
        const store = this.props.RulePreviewUI!;
        store.reset();
        removeAddNewLinesInCopyBuffer();
        window.removeEventListener('resize', this.resizeHandler);
        if (this.highlightBlockSub) {
            this.highlightBlockSub.unsubscribe();
        }
    }

    openRowPreviewDialog(content: string, resultTokens: PreviewIndexToken[]) {  
        const type = resultTokens.length > 0 ? resultTokens[resultTokens.length - 1].token.type : 'Text';
        const dialogWidth: string | number = type === 'Table' ? '90%' : 768;
        this.setState({showRowPreviewDialog: true, selectedRowContent: content, dialogWidth, selectedRowType: type});
    }

    handleResize() {
        if (this.state.selectedRowData.pkgId && this.state.selectedRowData.rowId) {  
            const store = this.props.RulePreviewUI!;
       
            reaction(() => store.pageCoordinates, (pc, r) => {
                if (pc && pc.length > 0 && (!store.selectedPackage || this.state.selectedRowData.pkgId === store.selectedPackage?.id)) {
                    this.highlightBlock(this.state.selectedRowData.rowId!, this.state.selectedRowData.pkgId! , true, false);
                    r.dispose();
                }
            });
        }
    }

    checkIfNodeIsExpanded(nodeId: string) {        
        return !!this.state.expandedNodes.find(n => n === nodeId);
    }
    
    checkIfNodeIsCollapsed(nodeId: string) {
        return !!this.state.collapsedNodes.find(n => n === nodeId);
    }

    addNewLines(text: string) {
        const res = [...text].map((c, i) => {

            if (c === '\n') {
                return <span key={i} style={{color: '#69b5e7'}}>{'\\n'}</span>;
            } else if (c === '\t') {
                return <span key={i} style={{color: '#69b5e7'}}>{'\\t'}</span>;
            } else {
                return c;
            }
            
        });
        return <>{res}</>;
    }

    highlightBlock(rowId: number, pkgId: string, selected: boolean | undefined, scrollToPos: boolean = true) {        
        const store = this.props.RulePreviewUI!;
        store.highlightBlock(rowId, pkgId, selected, scrollToPos);
        this.setState({selectedRowData: selected ? {rowId: rowId, pkgId: pkgId} : {rowId: undefined, pkgId: undefined}});           
    }

    selectPrevMatch() {
        const { searchFocusIndex, searchFoundCount } = this.state;
        this.setState({
            searchFocusIndex:
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex! - 1) % searchFoundCount
                : searchFoundCount - 1,
        });
    }

    selectNextMatch() {
        const { searchFocusIndex, searchFoundCount } = this.state;
        this.setState({
            searchFocusIndex:
            searchFocusIndex !== null
                ? (searchFocusIndex! + 1) % searchFoundCount
                : 0,
        });
    }

    getNodeTitleText(title: React.ReactElement): string {
        if (title.props && title.props.children && typeof title.props.children !== 'string') {
            return this.getNodeTitleText(title.props.children);
        }
        return title.props ? title.props.children : '';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchNode(searchData: SearchData) {
        const {node} = searchData;
        const store = this.props.RulePreviewUI!;
        let searchText = store.inputValue || '';
        if (searchText && searchText.length) {
            const title = node.title;
            const titleText = this.getNodeTitleText(title as React.ReactElement);
            try {
                var regex = new RegExp(searchText);
                return regex.test(titleText.toLowerCase());
            } catch {
                return false;
            }
        } else {
            return false;
        }
    }

    searchCallback(matches: object[]) {
        this.setState({
            searchFoundCount: matches.length,
            searchFocusIndex:
                  matches.length > 0 && this.state.searchFocusIndex !== null ? this.state.searchFocusIndex! % matches.length : 0,
        });
    }  

    getResult() {
        const store = this.props.RulePreviewUI!;

        if (!store.previewResults) {
            return <Empty style={{marginTop: 50}} />;
        }       

        const populateModalContent = () => {
            if (this.state.selectedRowContent) {
                if (this.state.selectedRowType === 'Table') {
                    return <TableBlockPreview content={this.state.selectedRowContent} />;
                }

                try {
                    const parsed = JSON.parse(this.state.selectedRowContent);
                    return <ReactJson src={parsed} displayDataTypes={false} />;
                } catch {
                    return this.addNewLines(this.state.selectedRowContent ?? '');
                }
            }

            return null;
        };

        return (
            <>
                <Row style={{whiteSpace: 'nowrap'}}>
                    <Col span={24}>
                        <Input.Search 
                            style={{margin: '20px 15px', width: 'calc(100% - 30px)'}} 
                            className="alpha-search-input"
                            allowClear
                            placeholder="Search..."
                            value={store.inputValue || ''}
                            onChange={v => {
                                store.setInputValue(v.currentTarget.value); 
                            }}  
                        />
                    </Col>
                    {/* <Col span={8}>
                        <Button style={{margin: 10}} disabled={this.state.searchFoundCount === 0} onClick={this.selectPrevMatch}>
                            <LeftOutlined />
                        </Button>
                        <Button style={{margin: '10px 0'}} disabled={this.state.searchFoundCount === 0} onClick={this.selectNextMatch}>
                            <RightOutlined />
                        </Button>
                    </Col>         */}
                </Row>
                
                <Modal
                    centered
                    width={this.state.dialogWidth}
                    className="alpha-portal-dialog two-columns"
                    visible={this.state.showRowPreviewDialog}
                    onCancel={() => this.setState({showRowPreviewDialog: false})}
                    footer={<Button onClick={() => this.setState({showRowPreviewDialog: false})}>Close</Button>}
                >
                    {populateModalContent()}
                </Modal>
                <SortableTree
                    isVirtualized
                    scaffoldBlockPxWidth={24}
                    canDrag={false}
                    canDrop={() => {
                        return false; 
                    }}
                    className="alpha-rst"
                    searchFocusOffset={this.state.searchFocusIndex}
                    searchFinishCallback={this.searchCallback}
                    searchQuery={store.inputValue || ''}                
                    searchMethod={this.searchNode}
                    reactVirtualizedListProps={{
                        rowHeight: 32
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    generateNodeProps={(rowInfo: any) => ({
                        className: 'alpha-sortable-tree-node',
                        onClick: (e: React.MouseEvent) => this.handleNodeClick(e, rowInfo),
                        data_id: `node-${rowInfo.node.title.props['data-id']}`
                    })}
                    treeData={this.state.treeData}                                    
                    onChange={(treeData: TreeItem[]) => this.setState({treeData: treeData})}
                    style={{paddingLeft: 24, height: 'calc(100% - 24px)' }}
                />
            </>
        );
    }

    renderFieldValues(line: PackageLine, fieldKey: string) {
        let item = line[fieldKey];                            
        if (typeof item === 'string' || typeof item === 'number') {
            return (
                <span 
                    data-id={`fields-${line.packageId}-${line.rowId}}-${fieldKey}`}                                                    
                >
                    <span>
                        {fieldKey}: {item}  
                    </span>
                </span>
            );
        } else if (fieldKey === 'tags') {
            return (
                <span data-id={`fields-${line.packageId}-${line.rowId}}-${fieldKey}`}> 
                    {fieldKey}: {
                        item && item.map((t: PackageTag) => (
                            <span key={`fields-${line.packageId}-${line.rowId}}-${fieldKey}-${t.tagId}`}>
                                {t.values.map((v: string) => <Tag key={`${line.rowId}-tag-${v}`}>{v}</Tag>)}
                            </span>
                        ))
                    }
                </span>
            );
        }

        return null;
    }

    convertObjectPropertiesIntoString(obj: object) {
        let result = '[';
        for (const key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                const element = obj[key];
                result += `${key}: ${element} `;
            }
        }

        return result + ']';
    }

    renderJsonPreview(val: string) {
        try {
            const parsed = JSON.parse(val);
            
            if (typeof parsed !== 'object') {
                return <span>{val}</span>;
            }

            return <ReactJson src={parsed} displayDataTypes={false} />;
        } catch {
            return <span>{val}</span>;
        }
    }

    renderAskAlphaIcon(packageId: string) {
        if (!this.props.project?.smartIndexSettings?.enabled) {
            return null;
        }

        const onClick = () => {
            const store = this.props.RulePreviewUI!;
            const existingInteractiveLabelsWindow = store.interactiveLabelsWindows.get(packageId);

            if (existingInteractiveLabelsWindow && !existingInteractiveLabelsWindow.closed) {
                existingInteractiveLabelsWindow.focus();
                return;
            }

            const url = `${window.location.origin}/projects/${this.props.project?.id}/interactivelabels/0/0?package_id=${packageId}&external_window_mode=true&open_ask_alpha=true`;
            const newInteractiveLabelsWindow = window.open(url, `interactive-labels-${packageId}`, 'height=900,width=1280');

            if (newInteractiveLabelsWindow) {
                newInteractiveLabelsWindow.focus();
                store.addInteractiveLabelsWindow(packageId, newInteractiveLabelsWindow);
            }
        };

        return (
            <Tooltip title="Ask Alpha">
                <CommentOutlined className="rules-ask-alpha-icon" onClick={onClick} />
            </Tooltip>
        );
    }

    mapPreviewResultsToTreeData() { 
        const store = this.props.RulePreviewUI!;        
        if (store.filteredPreviewResults && store.filteredPreviewResults.length) {
            const treeData = store.filteredPreviewResults.map(r => {
                let treeItem: TreeItem = {
                    title: (
                        <span package-id={r.packageId} data-id={`package-root-${r.packageId}`}>
                            {store.getPackageNameById(r.packageId)}
                            {this.renderAskAlphaIcon(r.packageId)}
                        </span>
                    ),
                    expanded: this.checkIfNodeIsExpanded(`package-root-${r.packageId}`),
                    children: r.previewResult.entries.map(e => {
                        let outputChildren: TreeItem[] = [];
                        let jsonObjPreview: JSX.Element | undefined = undefined;
                        const type = e.resultTokens.length > 0 ? e.resultTokens[e.resultTokens.length - 1].token.type : 'Text';

                        try {
                            jsonObjPreview = type === 'Table' 
                                ? <TableBlockPreview content={e.field.normalizedText} /> 
                                : this.renderJsonPreview(e.field.normalizedText);
                        } catch {
                            // do nothing
                        }

                        for (var resultToken of e.resultTokens) {
                            let resultTokenChildren: TreeItem[] = [];
                            
                            let outputChild: TreeItem = {
                                title: (<span data-id={`resultToken-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}`}>{resultToken.token.token}</span>),
                                expanded: this.checkIfNodeIsExpanded(`resultToken-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}`)
                            };

                            for (var rTokenKey in resultToken.token) {
                                if (resultToken[rTokenKey]) {
                                    resultTokenChildren.push({
                                        title: (
                                            <span 
                                                data-id={`resultToken-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}-${rTokenKey}`}
                                            >
                                                <span>
                                                    {rTokenKey}: {resultToken.token[rTokenKey]}
                                                </span>
                                            </span>),
                                        expanded: this.checkIfNodeIsExpanded(`resultToken-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}-${rTokenKey}`)
                                    });
                                }
                            }
                            outputChild.children = resultTokenChildren;

                            let contextChild: TreeItem = {
                                title: (<span data-id={`context-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}`}>context</span>),
                                expanded: this.checkIfNodeIsExpanded(`context-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}`),
                                children: []
                            };
                            
                            if (Object.getOwnPropertyNames(resultToken.context).length) {
                                for (var cTokenKey in resultToken.context) {                                    
                                    if (resultToken.context[cTokenKey]) {
                                        contextChild.children.push({
                                            title: (
                                                <span 
                                                    data-id={`context-${e.field.packageId}-${e.field.rowId}-${resultToken.token.token}-${cTokenKey}`}
                                                    style={{maxWidth: 280, overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}}
                                                >                                                    
                                                    {cTokenKey}:  {this.convertObjectPropertiesIntoString(resultToken.context[cTokenKey])}
                                                </span>)
                                        });
                                    }
                                }
                            } else {
                                contextChild.title = (<span>context [Empty]</span>);
                            }

                            outputChild.children.push(contextChild);
                            outputChildren.push(outputChild);
                        }
                        let inputChildren: TreeItem[] = [];

                        for (var token of e.tokens) {
                            let tokenChildren: TreeItem[] = [];
                            let inputChild: TreeItem = {
                                title: (<span data-id={`inputs-${e.field.packageId}-${e.field.rowId}-${token.token}`}>{token.token}</span>), 
                                expanded: this.checkIfNodeIsExpanded(`inputs-${e.field.packageId}-${e.field.rowId}-${token.token}`)
                            };
                            
                            for (var tokenKey in token) {
                                if (token[tokenKey] && (typeof(token[tokenKey]) === 'string' || typeof(token[tokenKey]) === 'number')) {
                                    tokenChildren.push({
                                        title: (
                                            <span 
                                                data-id={`inputs-${e.field.packageId}-${e.field.rowId}-${token.token}-${tokenKey}`}
                                            >
                                                <span>
                                                    {tokenKey}: {token[tokenKey]}
                                                </span>
                                            </span>),
                                    });
                                } else if (token[tokenKey]) {
                                    var tokenNestedChildren: TreeItem[] = [];

                                    for (var tKey in token[tokenKey]) {
                                        if (token[tokenKey][tKey] && (typeof(token[tokenKey][tKey]) === 'string' || typeof(token[tokenKey][tKey]) === 'number')) {
                                            tokenNestedChildren.push({
                                                title: (
                                                    <span 
                                                        data-id={`inputs-${e.field.packageId}-${e.field.rowId}-${token[tokenKey].token}-${tKey}`}
                                                    >
                                                        <span>
                                                            {tKey}: {token[tokenKey][tKey]}
                                                        </span>
                                                    </span>),
                                            });
                                        }
                                    }
                                    tokenChildren.push({
                                        title: (
                                            <span 
                                                data-id={`inputs-${e.field.packageId}-${e.field.rowId}-${token.token}-${tokenKey}`}
                                            >
                                                <span>
                                                    {tokenKey}
                                                </span>
                                            </span>),
                                        children: tokenNestedChildren
                                    });
                                }
                            }
                            inputChild.children = tokenChildren;
                            inputChildren.push(inputChild);
                        }

                        let fieldChildren: TreeItem[] = [];
                        for (var key in e.field) {
                            if (e.field[key]) {
                                fieldChildren.push({
                                    title: (
                                        <>
                                            {
                                                this.renderFieldValues(e.field, key)
                                            }
                                        </>)
                                });
                            }
                        }
                      
                        let tagChildren: TreeItem[] = e.tag.values.map((t, i) => {
                            let tagValJsonObject: JSX.Element | undefined = undefined;
    
                            try {
                                tagValJsonObject = type === 'Table' ? <TableBlockPreview content={t} /> : this.renderJsonPreview(t);
                            } catch {
                                // do nothing
                            }

                            return {   
                                title: (
                                    <>
                                        <Tooltip title="Copy value to clipboard">
                                            <Button
                                                style={{height: 24}}
                                                icon={<CopyOutlined style={{fontSize: 12, verticalAlign: 'baseline'}} />} 
                                                type="link"
                                                onClick={() => Utils.copyValToClipboard(t)}
                                            />
                                        </Tooltip>   
                                        <Popover 
                                            content={<div style={{maxWidth: 500, maxHeight: 400, overflow: 'auto'}}>{tagValJsonObject != null ? tagValJsonObject : t}</div>} 
                                            autoAdjustOverflow 
                                            placement="bottomLeft" 
                                        >
                                            <Tag key={`${e.field.rowId}-${t}-${i}`}>
                                                {this.addNewLines(t)}    
                                            </Tag>
                                        </Popover>
                                    </>)
                            };
                        });

                        let entryItem: TreeItem = {
                            title: (
                                <Popover  
                                    content={<div style={{width: 500, maxHeight: 400, overflow: 'auto'}}>{jsonObjPreview != null ? jsonObjPreview : e.field.normalizedText}</div>} 
                                    autoAdjustOverflow 
                                    data-id={`entry-${e.field.packageId}-${e.field.rowId}`}
                                    placement="bottomRight" >
                                    <span 
                                        className="tree-selectable-node"
                                        data-rowid={e.field.rowId}
                                        data-pkgid={e.field.packageId}
                                        data-selected={this.state.selectedRowData 
                                            && this.state.selectedRowData.rowId === e.field.rowId 
                                            && this.state.selectedRowData.pkgId === e.field.packageId ? 'true' : 'false' }
                                    >
                                        {e.field.normalizedText}
                                    </span>
                                </Popover>
                            ),
                            expanded: this.checkIfNodeIsExpanded(`entry-${e.field.packageId}-${e.field.rowId}`),
                            children: [{
                                title: (<span data-id={`tag-value-${e.field.packageId}-${e.field.rowId}`}>tag value</span>),
                                expanded: !this.checkIfNodeIsCollapsed(`tag-value-${e.field.packageId}-${e.field.rowId}`),
                                children: tagChildren
                            }, {
                                title: <span data-id={`debug-${e.field.packageId}-${e.field.rowId}`}><SearchOutlined /> debug</span>,
                                expanded: this.checkIfNodeIsExpanded(`debug-${e.field.packageId}-${e.field.rowId}`),
                                children: [{
                                    title: (<span data-id={`fields-root-${e.field.packageId}-${e.field.rowId}}`}>field</span>),
                                    expanded: this.checkIfNodeIsExpanded(`fields-root-${e.field.packageId}-${e.field.rowId}}`),
                                    children: fieldChildren
                                }, {
                                    title: (<span data-id={`input-root-${e.field.packageId}-${e.field.rowId}}`}>input</span>),
                                    expanded: this.checkIfNodeIsExpanded(`input-root-${e.field.packageId}-${e.field.rowId}}`),
                                    children: inputChildren
                                }, {
                                    title: (<span data-id={`output-root-${e.field.packageId}-${e.field.rowId}}`}>output</span>),
                                    expanded: this.checkIfNodeIsExpanded(`output-root-${e.field.packageId}-${e.field.rowId}}`),
                                    children: outputChildren
                                }]
                            }, {
                                title: (<span data-id={`text-root-${e.field.packageId}-${e.field.rowId}`}>text</span>),
                                expanded: !this.checkIfNodeIsCollapsed(`text-root-${e.field.packageId}-${e.field.rowId}`),
                                children: [{
                                    title: (
                                        <Popover  
                                            content={<div style={{width: 500, maxHeight: 500, overflow: 'auto'}}>{jsonObjPreview != null ? jsonObjPreview : this.addNewLines(e.field.normalizedText)}</div>} 
                                            autoAdjustOverflow 
                                            placement="bottomRight">
                                            <Tooltip title="Copy value to clipboard">
                                                <Button
                                                    style={{verticalAlign: 'middle', padding: '0 6px'}}
                                                    size="small"
                                                    shape="round"
                                                    icon={<CopyOutlined style={{fontSize: 12, verticalAlign: 'baseline'}} />} 
                                                    type="link"
                                                    onClick={() => Utils.copyValToClipboard(e.field.normalizedText)}
                                                />
                                            </Tooltip>
                                            <Button 
                                                style={{
                                                    verticalAlign: 'middle',
                                                    padding: '0 6px'
                                                }}
                                                size="small" 
                                                shape="round" 
                                                type="link" 
                                                icon={<EyeOutlined/>}
                                                onClick={() => this.openRowPreviewDialog(e.field.normalizedText, e.resultTokens)}                                            
                                            /> 
                                            <span style={{maxWidth: 270, overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', verticalAlign: 'middle'}}>
                                                {this.addNewLines(e.field.normalizedText)}
                                            </span>
                                        </Popover>
                                    )
                                }]
                            }]
                        };
                        
                        return entryItem;
                    })
                };
                return treeItem;
            });
            return treeData;
        }
        return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleNodeClick(e: React.MouseEvent, rowInfo: any) {
        if (e.target && (e.target as Element).className.includes && !(e.target as Element).className.includes('Button')) {
            const element = e.target as Element;
            const rowId = element.getAttribute('data-rowid');
            const pkgId = element.getAttribute('data-pkgid');
            const selected = element.getAttribute('data-selected') === 'true';
            if (rowId && pkgId && selected !== null) {
                this.highlightBlock(Number(rowId), pkgId, !selected);
                var nodes = document.getElementsByClassName('tree-selectable-node');
                for (let i = 0; i < nodes.length; i++) {
                    const node = nodes.item(i);
                    if (node) {
                        node.setAttribute('data-selected', 'false');
                    }
                }
                element.setAttribute('data-selected', String(!selected));
            }
        } else if (e.target && rowInfo.node) {
            const node = rowInfo.node.title as React.ReactElement;
            if (node && node.props) {
                const expanded = (e.target as Element).getAttribute('aria-label') === 'Expand';
                const dataId = node.props['data-id'] as string;
                if (expanded && dataId) {
                    const expandedNodes: string[] = [dataId];
                    const rootNode = this.state.treeData.find(d => d.title?.props['data-id'] === dataId);

                    if (rootNode) {
                        const expandedChildNodes = this.getExpandedChildNodes(rootNode);
                        expandedNodes.push(...expandedChildNodes);
                        
                        rootNode.children?.forEach((childNode: TreeItem) => {
                            const childNodeId = childNode.title?.props['data-id'];

                            if (childNodeId && expandedNodes.includes(childNodeId)) {
                                childNode.expanded = true;
                            }
                        });
                    }

                    this.setState({
                        expandedNodes: _.uniq([...this.state.expandedNodes, ...expandedNodes]),
                        collapsedNodes: this.state.collapsedNodes.filter(n => !expandedNodes.includes(n))
                    });
                } else {
                    this.setState({expandedNodes: this.state.expandedNodes.filter(n => n !== dataId)});
                    this.setState({collapsedNodes: [...this.state.collapsedNodes, dataId]});
                }
            }
        }
    }

    getExpandedChildNodes(rootNode: TreeItem) {
        const store = this.props.RulePreviewUI!;
        const packageId = rootNode.title?.props['package-id'];
        const data = store.filteredPreviewResults.find(p => p.packageId === packageId);

        if (!data) {
            return [];
        }

        return data.previewResult.entries.reduce<string[]>((acc, entry) => {
            const hasTags = entry.tag.values.some(v => v.length > 0);
            const hasText = entry.field.normalizedText.length > 0;

            if (hasTags && hasText) {
                acc.push(`entry-${entry.field.packageId}-${entry.field.rowId}`);
            }

            return acc;
        }, []);
    }

    onSplitterDragEnd(val?: number) {
        this.resizeHandler();
        window.dispatchEvent(new Event('resize'));
        if (val) {
            this.handlesecondPaneResize(val!);
        }
    }

    onPageWithBlockLoadHandler() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            this.resizeHandler();
        }, 500);
    }

    getPager() {
        const store = this.props.RulePreviewUI!;
        if (!store.selectedPackage) {
            return <div />;
        }

        return (
            <div className="rule-preview-pagination">
                <Pagination 
                    current={store!.currentPage + 1} 
                    total={store!.totalPages} 
                    defaultPageSize={1}
                    style={{display: store.totalPages !== 0 ? 'block' : 'none'}}
                    onChange={p => store!.setCurrentPage(p - 1)}
                />
            </div>);
    }

    handleFirstPaneResize(val: number) {
        sessionStorage.setItem('firstPanelWidth', val.toString());
    }

    handlesecondPaneResize(val: number) {
        sessionStorage.setItem('secondPanelWidth', val.toString());
    }

    render() {
        const store = this.props.RulePreviewUI!;

        return (
            <Layout className="screen-size rule-preview" style={{...{height: '100%', ...this.props.style}}}>
                <SplitterLayout 
                    percentage 
                    secondaryInitialSize={Number(sessionStorage.getItem('secondPanelWidth')) || 25}
                    onDragEnd={this.onSplitterDragEnd}
                >
                    <SplitterLayout percentage primaryIndex={1} secondaryInitialSize={Number(sessionStorage.getItem('firstPanelWidth')) || 25} onSecondaryPaneSizeChange={this.handleFirstPaneResize} onDragEnd={this.onSplitterDragEnd}>
                        <Sider 
                            id="rule-preview-package-filter-container" 
                            data-id="rule-preview-package-filter-container"
                            width={'100%'}
                            style={{height: 'calc(100% - 82px)'}}
                        >
                            <ProjectPackageFilter RulePreviewUI={store}/>  
                            <div className="rule-preview-run-container">
                                <Observer>
                                    {() => (
                                        <Button 
                                            data-id="rule-preview-run-button"
                                            type="primary" 
                                            size="large"
                                            disabled={!store!.checkedPackages.length || store!.isExecuting} 
                                            onClick={() => store!.execute().then(() => this.setState({treeData: this.mapPreviewResultsToTreeData()}))}
                                        >
                                Run test
                                        </Button>

                                    )}
                                </Observer>
                            </div>
                        </Sider>
                        <Content className="rule-results-container">
                            <div style={{width: '100%', height: '100%'}}>
                                {store!.isExecuting 
                                    ? (<Spin tip="Loading..." />) 
                                    : (
                                        <div style={{ height: 'calc(100% - 63px)'}}>
                                            {this.getResult()}
                                        </div>)}
                            </div>
                        </Content>
                    </SplitterLayout>
                    <Sider className="rule-document-preview-container" data-id="rule-preview-results-container" width={'100%'} style={{background: '#F5F6F8', height: 'calc(100% - 82px)'}}>
                        <div className="page-number-viewer" style={{visibility: store.currentPage !== -1 ? 'visible' : 'hidden'}}>{store.currentPage + 1}</div>
                        <div className={!store.selectedPackage ? 'empty-preview-title' : 'empty-preview-title hidden'}>
                            Tap on a document name to get a result’s preview.
                        </div>
                        <div id="rule-document-preview-container" style={{height: '100%', overflow: 'hidden'}}>
                            <PreviewContent store={store} pageHeaderHeight={260}/>
                        </div>
                        {this.getPager()}
                    </Sider>
                </SplitterLayout>
            </Layout>
        );
    }
}

export default observer(RulePreviewForm);