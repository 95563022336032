/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import { Form, Input, Table } from 'antd';
import * as React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { isNumber } from 'lodash';

type customTableProps = {
    editableRowIndex?: number;
    editableRowKey?: string;
    rowKeyColumn?: string;
    editableKeys?: string[];
    highlightedRowsIndexes?: number[];
    gridStyle?: React.CSSProperties;
    getHighlightedRowStyle?: (rowIndex: number) => React.CSSProperties
};

type VirtualTableProps =  Parameters<typeof Table>[0]  & customTableProps;


const VirtualTable: React.FC<Parameters<typeof Table>[0] & customTableProps > = (props: VirtualTableProps) => {
    const {
        columns,
        scroll,
        editableRowIndex,
        editableKeys,
        rowKeyColumn,
        editableRowKey,
        highlightedRowsIndexes,
        gridStyle,
        getHighlightedRowStyle
    } = props;

    const rowHeight = 47;
    const [tableWidth, setTableWidth] = useState(0);
    const widthColumnCount = columns!.filter(({ width }) => !width).length;
    const takenColWidth = columns!.filter(({ width }) => width).map(({ width }) => width).reduce((sum: number, current: number) => sum + current, 0) as number;
    const mergedColumns = columns!.map(column => {
        if (column.width) {
            return column;
        }

        let width = Math.floor((tableWidth - takenColWidth) / widthColumnCount);
        width = width < 100 ? 100 : width;
        return {
            ...column,
            width
        };
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gridRef = useRef<any>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [connectObject] = useState<any>(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => 0,
            set: (scrollLeft: number) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({ scrollLeft });
                }
            },
        });

        Object.defineProperty(obj, 'scrollTop', {
            get: () => 0,
            set: (scrollTop: number) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({ scrollTop });
                }
            },
        });

        return obj;
    });

    const resetVirtualGrid = () => {
        if (!gridRef.current) {
            return;
        }

        gridRef.current.resetAfterIndices({
            columnIndex: 0,
            shouldForceUpdate: true,
        });
    };

    useEffect(() => {
        resetVirtualGrid();
    }, [tableWidth]);

    const renderCell =  (columnIndex: number, rowIndex: number, rawData: object[]) => {
        const renderKey = 'render';
        if (!mergedColumns[columnIndex][renderKey]) {
            return (rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex];
        }

        const childrenKey = 'children';

        const renderResult = mergedColumns[columnIndex].render!((rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex], (rawData[rowIndex] as any), rowIndex);

        if (!renderResult) {
            return (rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex];
        }

        return renderResult[childrenKey] ?? renderResult;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderVirtualList = (rawData: object[], { scrollbarSize, ref, onScroll }: any) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * rowHeight;
        return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                style={gridStyle}
                columnCount={mergedColumns.length}
                columnWidth={(index: number) => {
                    const { width } = mergedColumns[index];
                    if (width === tableWidth) {
                        return calcColWidth(index);
                    }
                    return totalHeight > scroll!.y! && index === mergedColumns.length - 1
                        ? (width as number) - scrollbarSize - 1
                        : (width as number);
                }}
                height={scroll!.y as number}
                rowCount={rawData.length}
                rowHeight={() => rowHeight}
                width={tableWidth}
                onScroll={({ scrollLeft }: { scrollLeft: number }) => {
                    onScroll({ scrollLeft });
                }}
                
            >
                {({
                    columnIndex,
                    rowIndex,
                    style,
                }: {
                    columnIndex: number;
                    rowIndex: number;
                    style: React.CSSProperties
                }) => {
                    let styles = { ...style };

                    const isHighlightedRow = highlightedRowsIndexes ? highlightedRowsIndexes.includes(rowIndex) : false;

                    if (isHighlightedRow && getHighlightedRowStyle) {
                        styles = { ...styles, ...getHighlightedRowStyle(rowIndex) };
                    }
 
                    return (<div
                        className={columnIndex === mergedColumns.length - 1 ?  'virtual-table-cell-last' : 'virtual-table-cell'}
                        style={styles}
                    >
                        {
                            (editableRowIndex === rowIndex || (rowKeyColumn && rawData[rowIndex][rowKeyColumn] === editableRowKey)) && editableKeys?.includes((mergedColumns as any)[columnIndex].dataIndex) ?
                                <Form.Item  className="editable-cell-input-wrapper" name={(mergedColumns as any)[columnIndex].dataIndex}>
                                    <Input />
                                </Form.Item>
                                :  renderCell(columnIndex, rowIndex, rawData)
                        }
                    </div>);
                }}
            </Grid>
        );
    };

    const calcColWidth = (index: number) => {
        const colWithWidthSum =  mergedColumns
            .filter((c, i) => i !== index && isNumber(c.width))
            .map(c => c.width)
            .reduce((a: number, b: number) => a + b, 0) as number;
        let colWithoutWidthAmount = mergedColumns.filter((c) => !c.width || !isNumber(c.width)).length;
        colWithoutWidthAmount = colWithoutWidthAmount > 0 ? colWithoutWidthAmount : 1;

        const colWidth = (tableWidth - colWithWidthSum) / colWithoutWidthAmount;

        return colWidth <= 200 ? 200 : colWidth;
    };

    return (
        <ResizeObserver
            onResize={({ width }) => {
                setTableWidth(width);
            }}
        >
            <Observer>
                {() => (
                    <Table
                        {...props}
                        className={`virtual-table alpha-portal-table ${props.className}`}
                        columns={mergedColumns}
                        components={{
                            body: renderVirtualList,
                        }}
                    />
                )}
            </Observer>
        
        </ResizeObserver>
    );
};

export default observer(VirtualTable);