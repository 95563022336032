/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ApplicationDefinitionsService } from '../services';
import { computed, action, observable, runInAction } from 'mobx';
import {
    ApplicationDefinitionCreateModel,
    SettingsMetadata,
    IotaMetadataInput,
    SettingsMetadataSection,
    IotaExtension,
    IotaInputBindingsMeta,
    ApiOperationInfo,
    ApiApplicationCreateModel,
    InputBinding
} from '../types';
import {
    ApplicationDefinitionBase,
    ApplicationDefinition,
    ApplicationDefinitionBaseDto,
    ApplicationDefinitionConditional
} from '../types/ApplicationDefinition';
import { RcFile } from 'antd/lib/upload/interface';
import { ProjectsRootVisualStore } from '../../common/stores';
import ProjectApplicationDefinitionsTreeVisualStore from './ProjectApplicationDefinitionsTreeVisualStore';
import security from '../../common/services/SecurityService';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import { AlphaConfig } from '../../../modules/administration/models/AlphaConfig';
import { ALPHA_CONFIG_VARIABLES } from '../../../modules/administration/types/AlphaConfigModel';
import { AlphaConfigVisualStore } from '../../../modules/administration/stores/AlphaConfigVisualStore';
import { message } from 'antd';

export default class ProjectApplicationDefinitionsVisualStore {
    @observable
    currentAppDef: ApplicationDefinitionBase | undefined;

    @observable
    applicationDefinitions: ApplicationDefinitionBase[] = [];

    @observable
    iotaExtensions: IotaExtension[];

    @observable
    addDefinitionDialogVisible: boolean;

    @observable
    selectedSettingsMetadata: SettingsMetadata | undefined;

    @observable
    newIcon: RcFile | undefined;

    @observable
    currentAppDefIds: string [] = [];

    @observable
    isLoading: boolean = false;

    @observable
    settingsDialogVisible: boolean = false;

    @observable
    editAppDialogVisible: boolean = false;

    @observable
    isApiAppDialogVisible: boolean = false;

    @observable
    alphaConfigs:  AlphaConfig[];

    @observable
    currentOperations: ApiOperationInfo[] = [];

    @observable
    operationSearchText: string = '';

    @observable
    isLoadingApiOperations: boolean = false;

    @observable
    isSavingApiApplication: boolean = false;

    fileImportActionHeaders: {};

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    @computed
    get filteredOperations() {
        return this.currentOperations.filter(o => o.operationId.toLowerCase().includes(this.operationSearchText.toLowerCase()));
    }

    @computed
    get isInputDisabled() {
        return this.alphaConfigStore.alphaConfig.find(c=> c.name === ALPHA_CONFIG_VARIABLES.IotaInputReadonly)?.value !== 'false';
    }

    @computed
    get isSettingsDisabled() {
        return this.isInputDisabled && this.currentAppDef?.state === 'Published';
    }

    @computed
    get externalApiAppSettings() {
        var extension = this.iotaExtensions?.find(ext => ext.name === this.EXTERNAL_API_EXTENSION_NAME);
        return extension?.settings;
    }

    @computed
    get appHasConnectionAndSchema() {
        if (!this.currentAppDef || !this.currentAppDef.settings) {
            return false;
        }

        const settingsMetadata: SettingsMetadata = JSON.parse(this.currentAppDef.settings);
        const firstSection = settingsMetadata.sections[0];

        if (!firstSection) {
            return false;
        }

        const hasConnection = firstSection.inputs.some(i => i.id === 'SqlConnection');
        const hasTableSchema = firstSection.inputs.some(i => i.id === 'TableSchema');

        return hasConnection && hasTableSchema;
    }
    
    public EXTERNAL_API_EXTENSION_NAME = 'External API Plugin';
    public EXTERNAL_API_APPLICATION_NAME = 'ExternalApiApplication';
    public EXTERNAL_API_WORKFLOW_ID = 'ExternalApiClientApplication';

    constructor( private rootStore: ProjectsRootVisualStore, public groupTreeStore: ProjectApplicationDefinitionsTreeVisualStore,
                 private alphaConfigStore: AlphaConfigVisualStore, private appDefService: ApplicationDefinitionsService, private adminService: GlobalAdministrationService) {
        this.applicationDefinitions = [];
    }

    @action.bound
    setOperationSearchText(text: string) {
        this.operationSearchText = text;
    }

    @action.bound
    setIsApiAppDialogVisible(visible: boolean) {
        this.isApiAppDialogVisible = visible;
    }

    @action.bound
    setIsSavingApiApplication(saving: boolean) {
        this.isSavingApiApplication = saving;
    }

    @action.bound
    setCurrentAppDef(appDef: ApplicationDefinitionBase | undefined) {
        this.currentAppDef = appDef;
        appDef ? sessionStorage.setItem('currentAppDef', appDef.id) : sessionStorage.removeItem('currentAppDef');
    }

    @action.bound
    async setHeaders() {
        const tk = await security.invoke((token) => { 
            return Promise.resolve(token);
        });
        this.fileImportActionHeaders = {
            'Authorization': 'Bearer ' + tk
        };
    }

    @action.bound
    setApplicationDefinitions(appDefs: ApplicationDefinitionBase[]) {
        this.applicationDefinitions = appDefs;
    }

    @action.bound
    setIotaExensions(iotaExtensions: IotaExtension[]) {
        this.iotaExtensions = iotaExtensions;
    }

    @action.bound
    setAddDefinitionDialogVisible(visible: boolean) {
        this.addDefinitionDialogVisible = visible;
    }

    @action.bound
    setSelectedSettingsMetadata(settings: SettingsMetadata | undefined) {
        this.selectedSettingsMetadata = settings;
    }

    @action.bound
    setNewIcon(file: RcFile | undefined) {
        this.newIcon = file;
    }

    @action.bound
    async loadApplicationDefinitions() {
        if (this.currentProject) {
            runInAction(() => this.isLoading = true);
            const projectId = this.currentProject.id;
            await this.appDefService.syncMetadata(projectId);
            await this.appDefService.syncSettings(projectId);
            const appDefs = await this.appDefService.getApplicationDefinitionsForProject(projectId);

            for (let app of appDefs.filter(a => a.iconFileId)) {
                var icon = await this.appDefService.getApplicationIcon(app.id, projectId);

                if (icon) {
                    app.iconUrl = URL.createObjectURL(icon);
                }
            }

            this.setApplicationDefinitions(appDefs);
            this.groupTreeStore.setAppDefinitions(appDefs.slice());
            runInAction(() => this.isLoading = false);
            if (this.groupTreeStore.selectedKey) {
                this.groupTreeStore.handleNodeSelection(this.groupTreeStore.selectedKey);
            }
        }
    }

    @action.bound
    async loadIotaInputsMetadata() {
        const extensions = await this.appDefService.getMetadata();
        this.setIotaExensions(extensions);
    }

    @action
    handleEditAppClick(appId: string) {
        const appDef = this.applicationDefinitions.find( a => a.id === appId);
        this.setCurrentAppDef(appDef!);
        this.goToApplicationEdit(appDef!);
    }

    @action
    handleExportAppClick(appId: string) {
        this.appDefService.exportApp(appId, this.currentProject!.id);
    }

    @action
    async createApplicationDefinition(model: ApplicationDefinitionCreateModel) {
        var formData = new FormData();
        for (let key in model) {
            if (model[key]) {
                formData.append(key, model[key]);
            }
        }
        const response = await this.appDefService.createApplicationDefinition(formData, this.currentProject!.id);
        await response.asyncMap(async (appDefSaveSuccessModel) => {
            const data: ApplicationDefinitionBaseDto = {
                id: appDefSaveSuccessModel.id,
                type: model.type,
                name: model.name,
                applicationId: model.applicationId,
                workflowId: model.workflowId,
                projectId: model.projectId,
                extension: model.extension,
                state: model.state,
                meta: model.meta,
                settings: model.settings,
                iconFileId: appDefSaveSuccessModel.iconFileId
            };

            const newAppDef =
                data.type === 'ApplicationDefinitionConditional'
                    ? new ApplicationDefinitionConditional({ ...data, conditions: [], inputGroups: [] })
                    : new ApplicationDefinition({ ...data, bindings: [] });

            newAppDef.capabilities = model.capabilities ? JSON.parse(model.capabilities) : undefined;

            if (model.icon) {
                newAppDef.iconUrl = URL.createObjectURL(model.icon);
            }

            await runInAction(() => this.groupTreeStore.appDefinitions.push(newAppDef));
            await (runInAction(() => this.applicationDefinitions.push(newAppDef)));
            await this.groupTreeStore.addAplicationDefinition( appDefSaveSuccessModel.id);
            await this.groupTreeStore.loadApplicationDefinitionGroups();
            
            this.groupTreeStore.setFilteredApplicationDefinitions([newAppDef, ...this.groupTreeStore.filteredApplicationDefinitions]);
            this.setAddDefinitionDialogVisible(false);
        });

        return response;
    }

    @action
    async createApiApplicationDefinition(model: ApiApplicationCreateModel) {
        try {
            this.setIsSavingApiApplication(true);
            const response = await this.appDefService.createApiApplicationDefinition(model, this.currentProject!.id);

            if (response.isOk()) {
                await response.asyncMap(async (appDefSaveSuccessModel) => {
                    await this.groupTreeStore.addAplicationDefinition( appDefSaveSuccessModel.id);
                    await this.loadApplicationDefinitions();
                    await this.groupTreeStore.loadApplicationDefinitionGroups();
                    this.setIsApiAppDialogVisible(false);
                    message.success('Api application definition created successfully');
                });
              
            } else {
                message.error('Error creating api application definition');
            }
        } catch(ex) {
            message.error('Error creating api application definition');
            console.error(ex);
        } finally {
            this.setIsSavingApiApplication(false);
            this.setOperationSearchText('');
        }
    }

    @action
    async deleteApplicationDefinition(appDefId: string) {
        const response = await this.appDefService.deleteApplicationDefinition(appDefId, this.currentProject!.id);
        await response.asyncMap(async () => {
            await this.groupTreeStore.deleteApplicationDefinition(appDefId);

            const appDefinitionToDelete = this.applicationDefinitions.filter(a => a.id === appDefId);
            if (appDefinitionToDelete.length > 0) {
                let newList = this.applicationDefinitions.slice();
                const index = newList.indexOf(appDefinitionToDelete[0]);
                newList.splice(index, 1);
                this.setApplicationDefinitions(newList);
                this.groupTreeStore.setAppDefinitions(newList.slice());
            }
        });
    }

    @action
    async editApplicationDefinition(appDefId: string, newName: string) {
        const formData = new FormData();
        formData.append('applicationDefinitionId', appDefId);
        formData.append('newName', newName);

        if (this.newIcon) {
            formData.append('icon', this.newIcon);
        }
        const response = await this.appDefService.editApplicationDefinition(formData, appDefId, this.currentProject!.id);
        response.map(appDefSaveSuccessModel => {
            const appDefToRename = this.groupTreeStore.appDefinitions.filter(a => a.id === appDefId);

            if (appDefToRename.length > 0) {
                let newList = this.groupTreeStore.appDefinitions.slice();
                const index = newList.indexOf(appDefToRename[0]);
                runInAction(() => newList[index].name = newName);

                if (appDefSaveSuccessModel.iconFileId && this.newIcon) {
                    newList[index].iconFileId = appDefSaveSuccessModel.iconFileId;
                    newList[index].iconUrl = URL.createObjectURL(this.newIcon);
                }
                
                this.setApplicationDefinitions(newList);
                this.groupTreeStore.editAppDefinition(appDefSaveSuccessModel, this.newIcon, newName);
                this.groupTreeStore.setAppDefinitions(newList);
            }
            this.goToApplicationsList();
            this.setCurrentAppDef(undefined);
            this.setNewIcon(undefined);
            this.setEditAppDialogVisible(false);
        });

        return response;
    }

    @action.bound
    editInputs(id: string) {
        this.rootStore.navigateToEditApplicationDefinitionPage(this.rootStore.currentProject!, id);
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async updateApplicationSettings(appDefId: string, settings: { [id: string]: any }) {
        const response = await this.appDefService.updateApplicationSettings(appDefId, settings, this.currentProject!.id);
        response.map(() => {
            const appDefToUpdate = this.groupTreeStore.filteredApplicationDefinitions.filter(a => a.id === appDefId);

            if (appDefToUpdate && appDefToUpdate.length > 0) {
                const index = this.groupTreeStore.filteredApplicationDefinitions.indexOf(appDefToUpdate[0]);

                runInAction(() => {
                    this.groupTreeStore.filteredApplicationDefinitions[index].settingValues = settings;
                });
            }

            this.rootStore.navigateToApplicationDefinitionsPage(this.currentProject!);
            this.setCurrentAppDef(undefined);
            this.setSettingsDialogVisible(false);
        }); 
    }

    @action
    async updateApplicationState(appDefId: string, state: string) {
        const response = await this.appDefService.updateApplicationState(appDefId, state, this.currentProject!.id);

        response.map(() => {
            const filteredAppToUpdate = this.groupTreeStore.filteredApplicationDefinitions.find(a => a.id === appDefId);

            if (filteredAppToUpdate) {
                filteredAppToUpdate.setState(state);
                this.groupTreeStore.setFilteredApplicationDefinitions([...this.groupTreeStore.filteredApplicationDefinitions]);
            }

            const appToUpdate = this.groupTreeStore.appDefinitions.find(a => a.id === appDefId);

            if (appToUpdate) {
                appToUpdate.setState(state);
            }
        });
    }

    @action.bound
    setSettingsDialogVisible(visible: boolean) {
        this.settingsDialogVisible = visible;
    }

    @action.bound
    setEditAppDialogVisible(visible: boolean) {
        this.editAppDialogVisible = visible;
    }

    @action.bound
    goToSettingsPage(appDef: ApplicationDefinitionBase) {
        this.setCurrentAppDef(appDef);
        this.setSettingsDialogVisible(true);
    }

    @action
    goToApplicationsList() {
        this.rootStore.navigateToApplicationDefinitionsPage(this.currentProject!);
    }

    @action
    goToApplicationEdit(appDef: ApplicationDefinitionBase) {
        this.setCurrentAppDef(appDef);
        this.setEditAppDialogVisible(true);
    }

    @action
    async getApplicationIcon() {
        return await this.appDefService.getApplicationIcon(this.currentAppDef!.id, this.currentProject!.id);
    }

    @action
    validateSettingsAndInputs(appDef: ApplicationDefinitionBase) {
        let settingsAreValid = true;
        let bindingsAreValid = false;

        if (appDef.settings) {
            const settings: SettingsMetadata = JSON.parse(appDef.settings);
            if (settings.sections && settings.sections.length) {
                if (!appDef.settingValues) {
                    return false;
                }

                for (let section of settings.sections) {
                    for (let input of section.inputs) {
                        if (!this.validateInput(input, appDef)) {
                            settingsAreValid = false;
                            break;
                        }
                    }

                    if (section.sections) {
                        if (!this.validateSection(section, appDef)) {
                            settingsAreValid = false;
                            break;
                        }
                    }
                }
            }
        }

        let bindings: InputBinding[] | null = null;

        if (appDef instanceof ApplicationDefinition) {
            bindings = appDef.bindings;
        } else if (appDef instanceof ApplicationDefinitionConditional) {
            // Skip validation for now
            bindingsAreValid = true;
        }

        if (bindings && bindings.length) {
            const appInputsMeta: IotaInputBindingsMeta = JSON.parse(appDef.meta);
            bindingsAreValid = bindings.length > 0;
            const hasRequiredInputs = appInputsMeta 
                && appInputsMeta.inputs 
                && !!appInputsMeta.inputs.find(f => f.validation && 'required' in f.validation && f.validation.required.toString() === 'true');
            if (appInputsMeta && hasRequiredInputs) {
                for (let b of bindings) {
                    const inputMeta = appInputsMeta.inputs.find(i => i.id === b.inputId);
                    if (inputMeta && inputMeta.validation && 'required' in inputMeta.validation && inputMeta.validation.required.toString() === 'true' && !b.value) {
                        bindingsAreValid = false;
                        break;
                    }
                }
            }
        }

        return settingsAreValid && bindingsAreValid;
    }

    @action
    async deleteAppDefinitionGroup(id: string) {
        await this.appDefService.deleteApplicationDefinitionGroup(this.currentProject!.id, id);
        await this.groupTreeStore.loadApplicationDefinitionGroups();
        await this.loadApplicationDefinitions();
        this.groupTreeStore.setFilteredApplicationDefinitions([]);
    }

    @action
    async getAlphaConfigs() {
        this.alphaConfigs = await this.adminService.getAlphaConfigs();
    }

    @action.bound
    async getExternalApiOperations(url: string) {
        try {
            this.currentOperations = [];
            this.setIsLoadingApiOperations(true);
            this.currentOperations = await this.appDefService.getExternalApiOperations(url);
        } catch (ex) {
            message.error('Error loading api operations');
            console.error(ex);
        } finally {
            this.setIsLoadingApiOperations(false);
        }
    }

    @action.bound
    setIsLoadingApiOperations(loading: boolean) {
        this.isLoadingApiOperations = loading;
    }

    @action.bound
    getOperationBodyParameters(operationId: string) {
        return this.currentOperations.find(o => o.operationId === operationId)?.requestBodyParameters;
    }

    @action.bound
    getOperationParameters(operationId: string) {
        return this.currentOperations.find(o => o.operationId === operationId)?.parameters;
    }

    @action.bound
    async validateConnectionAndSchema(connectionString: string, tableSchema: string) {
        if (!connectionString.length || !tableSchema.length) {
            message.error('Connection string and table schema are required');
            return;
        }

        const resp = await this.appDefService.validateConnectionAndSchema(
            this.currentProject!.id,
            connectionString,
            tableSchema
        );

        if (resp.isOk()) {
            message.success('Connection string and table schema validated successfully');
            return;
        }

        message.error(`Error: ${resp.error.data ? resp.error.data.title : resp.error.text}`);
    }

    private validateInput(input: IotaMetadataInput, appDef: ApplicationDefinitionBase) {
        let inputIsvalid = true;
        if (input.validation) {
            let { validation } = input;

            if ('required' in validation && validation.required.toString() === 'true' && !this.checkInputHasValue(input, appDef)) {
                inputIsvalid = false;
            }
        }
        return inputIsvalid;
    }

    private checkInputHasValue(input: IotaMetadataInput, appDef: ApplicationDefinitionBase) {
        if (['string', 'number'].indexOf(input.type) < 0) {
            return true;
        }
        if (appDef.settingValues) {
            return appDef.settingValues[input.id] != null;
        } else {
            return false;
        }
    }

    private validateSection(section: SettingsMetadataSection, appDef: ApplicationDefinitionBase) {
        let sectionIsValid = true;
        if (section.sections) {
            for (let subSection of section.sections!) {
                for (let input of section.inputs) {
                    if (!this.validateInput(input, appDef)) {
                        sectionIsValid = false;
                        break;
                    }
                }

                if (section.sections) {
                    if (!this.validateSection(subSection, appDef)) {
                        sectionIsValid = false;
                        break;
                    }
                }
            }
        }

        return sectionIsValid;
    }
}
