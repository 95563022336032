export const ALPHA_CONFIG_VARIABLES = {
    IotaInputReadonly : 'PORTAL_IOTA_INPUT_READONLY',
    OcrParseTablesUsingML: 'IMPORT:OCR:TABLES:USE_ML',
    OcrParseTablesForceLattice: 'IMPORT:OCR:TABLES:FORCE_LATTICE',
    OcrParseCheckboxes: 'IMPORT:OCR:ENABLE_CHECKBOXES',
    OcrEnableRatesTables: 'IMPORT:OCR:TABLES:CUSTOM:RATES',
    OcrEnableAcordForms: 'IMPORT:OCR:TABLES:CUSTOM:ACORD',
    OcrRefineTablesWithTabula: 'IMPORT:OCR:TABLES:REFINE_LATTICE_WITH_TABULA',
    OcrParseTablesFastLattice: 'IMPORT:OCR:TABLES:FAST_LATTICE',
    OcrLang: 'IMPORT:OCR:LANG',
    OcrParseTablesLatticeLineScale: 'IMPORT:OCR:TABLES:LATTICE:LINE_SCALE',
    OcrParseTablesMlFixRotation: 'IMPORT:OCR:TABLES:ML:FIX_ROTATION',
    OcrParseTablesMlMissingLineScale: 'IMPORT:OCR:TABLES:ML:MISSING_LINE_SCALE',
    OcrParseTablesDisabled: 'IMPORT:OCR:TABLES:DISABLED',
    OcrImagePreprocess: 'IMPORT:OCR:IMAGE:PREPROCESS',
    OcrUseExternalEngine: 'IMPORT:OCR:USE_EXTERNAL_ENGINE',
    OcrTextReplacements: 'IMPORT:OCR:TEXT_REPLACEMENTS',
    OcrDisabledFixPagesRotation: 'IMPORT:OCR:DISABLED_FIX_PAGES_ROTATION',
    OcrIgnoreTextLayer: 'IMPORT:OCR:IGNORE_TEXT_LAYER',
    OcrEnableScannedDocumentsDetector: 'IMPORT:OCR:ENABLE_SCANNED_DOCUMENTS_DETECTOR',
    OcrSkipDuplicatePackageLookup: 'IMPORT:OCR:SKIP_DUPLICATE_PACKAGE_LOOKUP',
    OcrParseTablesApplyImageProcessing : 'IMPORT:OCR:TABLES:APPLY_IMAGE_PROCESSING',
    OcrTablesUseWordsForImageCells : 'IMPORT:OCR:TABLES:USE_WORDS_FOR_IMAGE_CELLS',
    OcrDisableDefaultImagePreprocessing : 'IMPORT:OCR:IMAGE:DISABLED_DEFAULT_PREPROCESSING',
    OcrBlocksCustom : 'IMPORT:OCR:BLOCKS:CUSTOM',
    OcrPackageUploadEnabled: 'IMPORT:OCR:PACKAGE:UPLOAD_ENABLED'
};