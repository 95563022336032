import { Project, PackageLine, PageCoordinates, Package, PackageState } from '../models';
import { gql } from 'apollo-boost';
import { ProjectResult, AllProjectResult, ProjectByIdResult, 
    PackageStateResult, SearchResult, 
    CommitLabelsequest, PackagesRequest, PackageLinesResponse, 
    PackagesResponse, SearchPackagesRequest, SearchPackagesResult,
    ProjectTagsResult, 
    PackageProblemReportResult,
    PackageProblemReport, 
    ProjectByUserResult } from '../types';
import security from './SecurityService';
import { execQuery } from './DataService';
import { ProjectKeywordRenameModel } from '../models';
import ProjectImportModel from '../../project_management/models/ProjectImportModel';
import appClient, { ResultApi } from './AppClient';
import { Utils } from './Utils';

const stateFilterValues = {
    [PackageState.Ready]: [PackageStateResult.Ready],
    [PackageState.Busy]: [PackageStateResult.Importing, PackageStateResult.Processing, PackageStateResult.Parsed],
    [PackageState.Broken]: [PackageStateResult.Broken]
};

export default class ProjectsService {
    async getProjects(state: PackageStateResult | null = PackageStateResult.Ready): Promise<ProjectResult[]> {
        var result = await execQuery<AllProjectResult>({
            query: gql`query allProject {
                allProjects {
                    id,
                    color,
                    name,
                    keywords,
                    type,
                    featureFlags,
                    #labels {
                    #    id,
                    #    keyword,
                    #    aliases
                    #},
                    #namedEntities {
                    #    id,
                    #    text,
                    #    label,
                    #    entities {
                    #        start,
                    #        end,
                    #        text
                    #    }
                    #},
                    #packages(state: ${state || 'null'}) {
                    #    id,
                    #    state,
                    #    projectId
                    #}
                }   
            }`,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.allProjects;
    }

    async getProjectsForCurrentUser(state: PackageStateResult | null = PackageStateResult.Ready): Promise<ProjectResult[]> {
        var result = await execQuery<ProjectByUserResult>({
            query: gql`query projectsByUser {
                projectsByUser {
                    id,
                    color,
                    name,
                    keywords,
                    type,
                    featureFlags,
                    smartIndexSettings,
                    # labels {
                    #     id,
                    #     keyword,
                    #     aliases
                    # },
                    packages(state: ${state || 'null'}) {
                        id,
                        state,
                        projectId
                    },
                    tagsVersion {
                        version,
                        versionHash
                    }
                }   
            }`,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.projectsByUser;
    }

    async getProject(id: string): Promise<ProjectResult | null> {
        var result = await execQuery<ProjectByIdResult>({
            query: gql`query getProject($id:String!) {
                projectById(id: $id) {
                    id,
                    color,
                    name,
                    type,
                    keywords,
                    featureFlags,
                    smartIndexSettings,
                    labels {
                        id,
                        keyword,
                        aliases
                    }
                }   
            }`,
            variables: {
                id: id
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.projectById) {
            if (result.errors) {
                throw result.errors[0];
            }
            
            return null;
        }

        return result.data.projectById;
    }

    // eslint-disable-next-line max-len
    async getPackages(project: Project, state: PackageStateResult = PackageStateResult.Ready): Promise<ProjectResult | null> {
        var result = await execQuery<ProjectByIdResult>({
            query: gql`query getPackagesForProject($id:String!) {
                projectById(id: $id) {
                    id,
                    color,
                    keywords
                    # labels {
                    #     id,
                    #     keyword,
                    #     aliases
                    # },
                    # namedEntities {
                    #     id,
                    #     text,
                    #     label,
                    #     entities {
                    #         start,
                    #         end,
                    #         text
                    #     }
                    # },
                    #packages(state: ${state}) {
                    #    id,
                    #    fileName,
                    #    filePath,
                    #    projectId,
                    #    contentType,
                    #    state,
                    #    operationState,
                    #    userTags,
                    #    indexDate,
                    #    sortingDate,
                    #    uploadedTime,
                    #    source,
                    #    error,
                    #    featureFlags
                    #}
                }   
            }`,
            variables: {
                id: project.id
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.projectById) {
            return null;
        }
        
        return result.data.projectById;
    }

    async addProject(proj: Project): Promise<ResultApi<string>> {
        const request = {
            name: proj.title,
            type: proj.type,
            keywords: proj.keywords,
            color: proj.color,
            featureFlags: proj.featureFlags
        };

        const formData = new FormData();
        for (let key in request) {
            if (request[key]) {
                formData.append(key, request[key]);
            }
        }
        const url = process.env.REACT_APP_MANAGE_URL + 'projects';
        return appClient.post<string>(url, formData);
    }

    async updateProject(
        projectId: string,
        name: string,
        keywords: string[],
        color: string,
        featureFlags: string | null,
        smartIndexSettings: string | null
    ): Promise<ResultApi<unknown>> {
        const request = {
            name,
            keywords,
            color,
            featureFlags,
            smartIndexSettings
        };
        const formData = new FormData();
        
        for (let key in request) {
            if (request[key]) {
                formData.append(key, request[key]);
            }
        }

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/edit`;
        return appClient.post(url, formData);
    }

    async exportProject(projectId: string, exportParts?: string[]) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'projectExportPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        if (exportParts && exportParts.length) {
            exportParts.forEach(o => {
                const exportOptionInput = document.createElement('input');
                exportOptionInput.type = 'text';
                exportOptionInput.name = 'exportParts[]';
                exportOptionInput.value = o;
                mapForm.appendChild(exportOptionInput);
            });
        }

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/export${t}`;
            Utils.downloadFile(url, mapForm, 'projectExportPostForm');
            return Promise.resolve();
        });
    }

    async importProject(formData: FormData): Promise<ResultApi<ProjectImportModel>> {
        const url = process.env.REACT_APP_MANAGE_URL + 'projects/import';
        return await appClient.post(url, formData);
    }

    deleteProject(projectId: string): Promise<ResultApi<unknown>> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}`;
        return appClient.delete(url);
    }

    async updateProjectMetadata(proj: Project, renameList: ProjectKeywordRenameModel[]): Promise<void> {
        const request = {
            projectId: proj.id,
            keywords: proj.keywords,
            renameList: renameList || []
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${request.projectId}/update/metadata`;
        await appClient.update(url, request);  
    }

    async getPackageLines(proj: Project, request: PackagesRequest, pkg?: Package | undefined): Promise<PackageLinesResponse> {
        var { projectId, pkgId, search, pageSize, page, fieldsSearch, blockTypes, tags, searchPage } = request;
 
        if (!fieldsSearch) {
            fieldsSearch = {};
        }
        if (!blockTypes) {
            fieldsSearch.before = {blockType: 'LINE_BLOCK' };
        } else {
            if (blockTypes!.length > 1) {
                fieldsSearch.before = { $or: blockTypes!.map( b => {
                    return ({blockType: b}); 
                })};
            }  else {
                fieldsSearch.before = { blockType: blockTypes!.length && blockTypes![0]  || 'LINE_BLOCK' };
            }
        }

        if (searchPage != null) {
            fieldsSearch.after = {page: searchPage};
        }
        if (tags && tags.length > 0) {
            fieldsSearch.packageMatch = { userTags: { $in: tags || [] } };
        }

        var result = await execQuery<SearchResult>({
            /* eslint-disable max-len */
            query: gql`query getPackagesForProject($projId:String!, $pkgId: String, $searchTerm: String, $pageSize: Int!, $page: Int = 0, $fieldsSearch: Json = null) {
                searchPackageLines(projId:$projId, pkgId:$pkgId, searchTerm:$searchTerm, pageSize:$pageSize, page:$page, fieldsSearch: $fieldsSearch) {
                    result {
                        fieldId
                        rowId
                        page
                        x
                        y
                        w
                        h
                        pageWidth
                        pageHeight
                        text
                        normalizedText
                        imagePath
                        hasImage,
                        packageId,
                        label,
                        additionalProperties,
                        blockType
                      },
                    pageInfo {
                        total
                    }
                }
            }`,
            variables: {
                projId: projectId,
                pkgId,
                searchTerm: !search || search.length === 0 ? null : search,
                pageSize,
                page: page || 0,
                fieldsSearch: fieldsSearch || null
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.searchPackageLines.result) {
            if (result.errors) {
                throw new Error(result.errors!.toString()); 
            }

            return new PackageLinesResponse();
        }

        var response = new PackageLinesResponse();
        response.total = result.data.searchPackageLines.pageInfo.total;
        response.lines = result.data.searchPackageLines.result.map(r => {
            if (!pkg) {
                pkg = proj.packages.filter(p => p.id === r.packageId)[0];
            }
            const label = r.label ? proj.labels.find(l => l.text === r.label) : null;

            return new PackageLine(r.fieldId, pkg, r.rowId, r.text, r.normalizedText, 
                label, r.additionalProperties, r.imagePath, 
                new PageCoordinates(r.pageWidth, r.pageHeight, r.x, r.y, r.w, r.h, r.page),
                r.blockType || 'TEXTBOX_BLOCK');
        });

        return response;
    }

    async searchPackages(proj: Project, request: SearchPackagesRequest): Promise<PackagesResponse> {
        let { projectId, search, pageSize, page, fieldsSearch, tags, allSources, orderBy, state, protectedOnly, uploadedBy, packageIds } = request;

        if (!fieldsSearch) {
            fieldsSearch = {
                packageMatch: {}
            };
        }

        if (tags && tags.length > 0) {
            fieldsSearch.packageMatch.userTags = { $in: tags || [] };
        }

        if (state && state.length > 0) {
            fieldsSearch.packageMatch.state = { $in: state.reduce((acc, value) => [...acc, ...stateFilterValues[value]], []) };
        }

        var result = await execQuery<SearchPackagesResult>({
            /* eslint-disable max-len */
            query: gql`
                query searchPackagesForProject(
                    $projectIds: [String]!
                    $searchTerm: String
                    $pageSize: Int!
                    $page: Int = 0
                    $allSources: Boolean
                    $fieldsSearch: Json = null
                    $orderBy: Json = null
                    $distinctBy: String
                    $protectedOnly: Boolean
                    $uploadedBy: String
                    $packageIds: [String]
                ) {
                    searchPackages(
                        projectIds: $projectIds
                        searchTerm: $searchTerm
                        pageSize: $pageSize
                        page: $page
                        allSources: $allSources
                        fieldsSearch: $fieldsSearch
                        orderBy: $orderBy
                        distinctBy: $distinctBy
                        protectedOnly: $protectedOnly
                        uploadedBy: $uploadedBy
                        packageIds: $packageIds
                    ) {
                        result {
                            id
                            fileName
                            state
                            operationState
                            filePath
                            userTags
                            uploadedTime
                            indexDate
                            contentType
                            source
                            error
                            featureFlags
                            sortingDate
                            isProtected
                            uploadedBy
                        }
                        pageInfo {
                            total
                        }
                    }
                }
            `,
            variables: {
                projectIds: [projectId],
                searchTerm: !search || search.length === 0 ? null : search,
                pageSize,
                page: page || 0,
                fieldsSearch: fieldsSearch || null,
                allSources: allSources,
                orderBy: orderBy || null,
                distinctBy: null,
                protectedOnly: protectedOnly,
                uploadedBy: uploadedBy,
                packageIds
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.searchPackages.result) {
            if (result.errors) {
                throw new Error(result.errors!.toString()); 
            }

            return new PackagesResponse();
        }

        var response = new PackagesResponse();
        response.total = result.data.searchPackages.pageInfo.total;
        response.lines = result.data.searchPackages.result.map(r => 
            new Package(proj, r.id, r.fileName, 
                r.contentType,
                r.state === PackageStateResult.Ready ? PackageState.Ready : r.state === PackageStateResult.Broken ? PackageState.Broken : PackageState.Busy, 
                r.filePath, undefined, r.userTags, r.source, r.uploadedTime, r.indexDate, r.error, r.featureFlags ?? undefined, r.isProtected ?? false));

        return response;
    }

    async commitLabelsProject(request: CommitLabelsequest): Promise<void> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${request.projectId}/update/labels`;
        await appClient.update(url, request);
    }

    async getProjectTags(projectId?: string) {
        var result = await execQuery<ProjectTagsResult>({
            query: gql`query projectTags($projectId:String) {
                projectTags(projectId: $projectId) {
                    tag
                }   
            }`,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.projectTags.map(x => x.tag);
    }

    handleDownload(id: string, fileType?: 'pdf'| 'apkg') {
        let path = encodeURIComponent(id);
        security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            let url = '';
            if (!fileType || fileType === 'pdf') {
                url = `${process.env.REACT_APP_MANAGE_URL}document/${path}${t}`;
            } else {
                url = `${process.env.REACT_APP_MANAGE_URL}file/${fileType}/${path}${t}`;
            }
            console.log(url);
            window.open(url);
            return Promise.resolve();
        });
    }

    async handleArchiveDownload(ids: string[], fileType: 'pdf'| 'apkg') {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'downloadPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        ids.forEach(r => {
            const mapRuleIdInput = document.createElement('input');
            mapRuleIdInput.type = 'text';
            mapRuleIdInput.name = 'ids[]';
            mapRuleIdInput.value = r;
            mapForm.appendChild(mapRuleIdInput);
        });
       
        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            let url = '';
            if (fileType === 'pdf') {
                url = `${process.env.REACT_APP_MANAGE_URL}document/${t}`;
            } else {
                url = `${process.env.REACT_APP_MANAGE_URL}file/${fileType}/${t}`;
            }
            Utils.downloadFile(url, mapForm, 'downloadPostForm');
            return Promise.resolve();
        });
    }


    async updatePackageUserTags(pkg: Package, tags: string[]) {
        const url = process.env.REACT_APP_MANAGE_URL + `packages/${pkg.id}/usertags`;
        await appClient.post(url, tags);
    }

    async savePackageProblemMessage(packageId: string, text: string, page: number) {
        const url = process.env.REACT_APP_MANAGE_URL + `problems_report/${packageId}/${page}`;
        return appClient.post(url, {text});

    }

    async getPackageProblemMessages(projectId: string): Promise<PackageProblemReport[] | null> {
        const result = await execQuery<PackageProblemReportResult>({
            query: gql`query getPackageProblemMessages($projectId:String!) {
                getPackageProblemMessages(projectId: $projectId) {
                    id,
                    packageId,
                    reports,
                }
            }`,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getPackageProblemMessages;
    }

    async setProblemStatus(status: string, recordId: string, problemId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `problems_report/${recordId}/${problemId}/set_state`;
        await appClient.post(url, status);
    }

    async updatePackageFeatureFlags(packageId: string, featureFlags: unknown) {
        const url = process.env.REACT_APP_MANAGE_URL + `packages/${packageId}/change-feature-flags`;
        return await appClient.post(url, featureFlags);
    }

    async exportProjectFeautureFlags(projectId: string) {
        const mapForm = document.createElement('form');
        
        mapForm.setAttribute('id', 'exportProjectFeautureFlagsForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/export-feature-flags${t}`;
            Utils.downloadFile(url, mapForm, 'exportProjectFeautureFlagsForm');
            return Promise.resolve();
        });
    }

    async exportPackageFeautureFlags(packageId: string) {
        const mapForm = document.createElement('form');
        
        mapForm.setAttribute('id', 'exportPackageFeautureFlagsForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `packages/${packageId}/export-feature-flags${t}`;
            Utils.downloadFile(url, mapForm, 'exportPackageFeautureFlagsForm');
            return Promise.resolve();
        });
    }
}
