
import * as React from 'react';
import { getFriendlyRuleTypeName } from '../../common/Utils';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';
import Select from 'antd/lib/select';
import { Form } from 'antd';
import { RULE_TYPES } from '../stores/RuleNewVisuaStoreBase';
type Props = {
    selectType?: (type: RuleType) => void;
    initialValue: RuleType;
    resetFormValues: () => void
};
const {Option} = Select;
export const RuleTypeSelect: React.FC<Props> = ({selectType, initialValue, resetFormValues}) => {  
    const types = selectType && RULE_TYPES as string[] || [initialValue];
    const handleOnChange = (v: RuleType) => {
        selectType!(v);
        resetFormValues();
    };
    return (
        <Form.Item
            label="Rule type"
            name="ruleType"
            rules={[{
                required: true, message: 'Please input rule type!',
            }]}
            initialValue={initialValue}
            data-id="rule-type-select" 
        >    
            <Select 
                disabled={!selectType}
                onChange={handleOnChange}
                virtual={false}
            >
                {types.map((t: RuleType) => (<Option key={t} value={t}>{getFriendlyRuleTypeName(t)}</Option>))}
            </Select>
        </Form.Item>
    );
};

export default observer(RuleTypeSelect);