import { TreeFeatureFlagModel } from '.';

export default class FeatureFlagTreeNodeModel {
    readonly key: string;

    readonly keyPart: string;

    readonly children: FeatureFlagTreeNodeModel[] = [];

    featureFlag?: TreeFeatureFlagModel;

    constructor(key: string, keyPart: string) {
        this.key = key;
        this.keyPart = keyPart;
    }

    setFeatureFlag(featureFlag: TreeFeatureFlagModel) {
        this.featureFlag = featureFlag;
    }
}
