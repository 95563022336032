import TagRuleBase from './TagRuleBase';
import { observable } from 'mobx';
import { ElasticSearchRegexpQueryRuleResult, RuleError, RegexFlagKey } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class ElasticSearchRegexpQueryRule extends TagRuleBase<ElasticSearchRegexpQueryRule, ElasticSearchRegexpQueryRuleResult> {
    @observable
    value: string;

    @observable
    flags?: RegexFlagKey[];

    @observable
    caseInsensitive: boolean;

    @observable
    excludedBlockTypes: string[];

    constructor(
        id: string | null,
        projectId: string | null,
        name: string,
        tagId: string | null,
        tag: string,
        value: string,
        caseInsensitive: boolean,
        pipeline: PipelineStep<PipelineStepType>[] = [],
        groupId: string | null,
        flags?: RegexFlagKey[],
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        excludedBlockTypes: string[] = [],
        errors?: RuleError[]
    ) {
        super(
            id,
            projectId,
            name,
            tagId,
            tag,
            'ElasticSearchRegexpQueryRule',
            pipeline,
            groupId,
            updateDate,
            priority,
            description,
            state,
            status,
            overridePriority,
            errors
        );
        this.value = value;
        this.flags = flags;
        this.caseInsensitive = caseInsensitive;
        this.excludedBlockTypes = excludedBlockTypes || [];
    }

    clone(): ElasticSearchRegexpQueryRule {
        return new ElasticSearchRegexpQueryRule(
            this.id,
            this.projectId,
            this.name,
            this.tagId,
            this.tag,
            this.value,
            this.caseInsensitive,
            this.clonePipeline(),
            this.groupId,
            this.flags,
            this.updateDate,
            this.priority,
            this.description,
            this.state,
            this.status,
            this.overridePriority,
            this.excludedBlockTypes
        );
    }

    validateState(): string[] {
        if (!this.name || !this.value || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): ElasticSearchRegexpQueryRuleResult {
        const json: ElasticSearchRegexpQueryRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,
            value: this.value,
            flags: this.flags,
            caseInsensitive: this.caseInsensitive,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority,
            excludedBlockTypes: this.excludedBlockTypes
        };

        return json;
    }
}
