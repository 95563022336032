import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { TagModel } from '../models/TagModel';
import { RulesListVisualStore } from '../stores';

interface Props {
    store: RulesListVisualStore;
    tag: TagModel
}

const TagErrorIcon: React.FC<Props> = ({ store, tag }: Props) => {
    const showIcon = store.tagHasBrokenRules(tag.id!);

    const getTooltipTitle = () => {
        if (!showIcon) {
            return null;
        }

        const tagRules = store.filteredRules[tag.name];
        const tagRulesWithErors = tagRules?.filter(rule => rule.errorMessages.length > 0);

        if (!tagRulesWithErors.length) {
            return null;
        }

        return (
            <div>
                {tagRulesWithErors.map(rule => (
                    <div key={rule.name}>
                        <strong>{rule.name}</strong>
                        <ul style={{ marginBottom: 0 }}>
                            {rule.errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Tooltip title={getTooltipTitle()} placement="topLeft" overlayStyle={{ maxWidth: 800 }}>
            <i
                className="alpha-icon md rule-problem-icon tag-with-broken-rule"
                style={{ display: showIcon ? 'visible' : 'none' }}
            />
        </Tooltip>
    );
};

export default observer(TagErrorIcon);
