/* eslint-disable @typescript-eslint/member-ordering */

import { computed, action, observable, reaction } from 'mobx';
import RuleEditVisualStore from './RuleEditVisualStore';
import type { RuleTypes } from '../models';
import { PipelineStep } from '../models';
import { RouterStore } from '../../common/stores';
import RulesStore from './RulesStore';
import { RulesPagesNavigation } from '../routes';
import type RuleType from '../types/RuleType';
import PipelineStepType from '../types/PipelineStepType';
import { ResultApi } from '../../common/services/AppClient';

export const RULE_TYPES = [
    'ElasticSearchMatchPhraseRule',
    'ElasticSearchMatchRule',
    'ElasticSearchQueryRule',
    'ElasticSearchRawRule',
    'ElasticSearchScriptQueryRule',
    'ElasticSearchFuzzyQueryRule',
    'ElasticSearchRegexpQueryRule',
    'ElasticSearchWildcardQueryRule',
    'RefDataRule',
    'InferenceRule',
    'NamedEntitiesRecognitionRule',
    'SmartIndexRule'
] as RuleType[];

abstract class RuleNewVisuaStoreBase {
    @observable
    editableRule?: RuleTypes;

    types: RuleType[] = RULE_TYPES;

    copiedPipeline: PipelineStep<PipelineStepType>[] = [];

    @observable
    isCreateDialogVisible: boolean = false;

    @computed
    get pipeline() {
        return this.editableRule!.pipeline;
    }

    @computed
    get connections() {
        return this.store.connections;
    }

    @computed
    get currentTab() {
        return this.editStore.currentTabsKey;
    }

    get currentTagId () {
        return this.editStore.currentTagId;
    }

    @observable
    error: string[] = [];

    constructor(public store: RulesStore, private routerStore: RouterStore, public editStore: RuleEditVisualStore) {
        reaction(() => this.routerStore.location, () => {
            this.selectType(undefined); 
        });
    }

    abstract selectType(v?: RuleType): void;

    abstract get currentGroupId(): string;

    @action
    selectNewType(pipeline: PipelineStep<PipelineStepType>[], v?: RuleType) {
        this.editStore.selectNewType(pipeline, v);
    }

    @action
    async save(edit: RuleTypes): Promise<ResultApi<RuleTypes> | undefined> {
        const res = await this.store.save(edit);
        if (res) {
            this.routerStore.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', this.store.currentProject!.id));
        }
        return res;
    }

    @action
    cancel() {
        this.routerStore.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', this.store.currentProject!.id));
    }

    @action.bound
    setIsCreateDialogVisible(visible: boolean) {
        this.isCreateDialogVisible = visible;
    }
}

export default RuleNewVisuaStoreBase;