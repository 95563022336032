import TagRuleBase from './TagRuleBase';
import { observable } from 'mobx';
import { NamedEntitiesRecognitionRuleResult, RuleError } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class NamedEntitiesRecognitionRule extends TagRuleBase<NamedEntitiesRecognitionRule, NamedEntitiesRecognitionRuleResult> {
    @observable
    modelId: string | null;

    @observable
    entities: string[];

    @observable
    blockType: string;

    @observable
    pageRange: string;

    constructor(
        id: string | null,
        projectId: string | null,
        name: string,
        tagId: string | null,
        tag: string,
        modelId: string | null,
        entities: string[],
        pipeline: PipelineStep<PipelineStepType>[] = [],
        groupId: string | null,
        pageRange: string,
        blockType: string,
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        errors?: RuleError[]
    ) {
        super(
            id,
            projectId,
            name,
            tagId,
            tag,
            'NamedEntitiesRecognitionRule',
            pipeline,
            groupId,
            updateDate,
            priority,
            description,
            state,
            status,
            overridePriority,
            errors
        );
        this.modelId = modelId;
        this.entities = entities;
        this.pageRange = pageRange;
        this.blockType = blockType;
    }

    clone(): NamedEntitiesRecognitionRule {
        // eslint-disable-next-line max-len
        return new NamedEntitiesRecognitionRule(
            this.id, 
            this.projectId, 
            this.name, 
            this.tagId, 
            this.tag, 
            this.modelId,
            this.entities, 
            this.clonePipeline(), 
            this.groupId, 
            this.pageRange, 
            this.blockType, 
            this.updateDate, 
            this.priority,
            this.description,
            this.state,
            this.status,
            this.overridePriority
        );
    }

    validateState(): string[] {
        if (!this.name || !this.entities || !this.entities.length || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): NamedEntitiesRecognitionRuleResult {
        const json: NamedEntitiesRecognitionRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,     
            blockType: this.blockType,
            pageRange: this.pageRange,
            nerModelId: this.modelId,
            entities: this.entities,
            tagId: this.tagId,
            tag: this.tag, 
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority
        };

        return json;
    }
}