import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'antd';
import WrappedApplicationSettingsForm from '../../iota_applications/components/ApplicationSettingsForm';
import { IotaConnStores } from '../stores';
import { STORE_PROJECT_CONNECTIONS_DEFINITIONS } from '../constants';

type Props = IotaConnStores;

export const EditConnectionsSettingsPage: React.FC<Props> = ({ projectConnectionsDefinitionsUI: store }) => {
    const [settingsForm] = Form.useForm();
    return (
        <div style={{ padding: '24px' }}>
            <Row>
                <Col span={12}>
                    {store!.current ? (
                        <WrappedApplicationSettingsForm 
                            settingsForm={settingsForm}
                            settingsJson={store!.current!.settings!} 
                            settingsValues={store!.current!.settingValues!}
                            back={() => store!.goToList()}
                            id={store!.current!.id}
                            update={(id, settings) => store!.updateSettings(id, settings)}                        
                        />) : (<div />)}
                </Col>
            </Row>
        </div>
    );
};

export default inject(STORE_PROJECT_CONNECTIONS_DEFINITIONS)(observer(EditConnectionsSettingsPage));