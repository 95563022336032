import { Input, Form, Radio, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import * as React from 'react';
import { ProjectFormVisualStore } from '../stores';
import { useForm } from 'antd/lib/form/Form';
import FeatureFlagsRenderer from '../../common/components/FeatureFlagsRenderer';
import { observer } from 'mobx-react-lite';
import {
    transformFeatureFlags,
    importFeatureFlags,
    featureFlagsOverrideKeys,
    FeatureFlagsOverride,
    setFormFeatureFlags
} from '../../common/FeatureFlagsHelpers';
import { SmartIndexSettingsEditor } from '.';
import { SmartIndexSettings } from '../../common/types';

const FormItem = Form.Item;

type EditProjectFormProps = {
    store: ProjectFormVisualStore;
    resetOnSubmit?: boolean
};

interface Hash {
    [details: string]: string & string[] & SmartIndexSettings
}

export const EditProjectForm: React.FC<EditProjectFormProps> = ({ store, resetOnSubmit }) => {
    const [form] = useForm();

    const [featureFlagFileList, setFeatureFlagFileList] = React.useState<UploadFile[]>([]);

    const [featureFlagsOverride, setFeatureFlagsOverride] = React.useState<FeatureFlagsOverride>({
        overrideFeatureFlags: false,
        overrideImageFeatureFlags: false,
        overrideTextFeatureFlags: false
    });

    const [instructWorkflowRequired, setInstructWorkflowRequired] = React.useState(false);

    const setProjectFeatureFlags = React.useCallback(() => {
        setFeatureFlagsOverride({
            ...setFormFeatureFlags(form, store.project?.storedFeatureFlags)
        });
    }, [form, store]);

    React.useEffect(() => {
        store.getInstructWorkflows();
        setInstructWorkflowRequired(!!store.project?.smartIndexSettings?.enabled);
        setProjectFeatureFlags();
        return () => {
            setFeatureFlagFileList([]);
            form.resetFields();
        };
    }, [form, store, store.project, setProjectFeatureFlags]);

    if (!store.project) {
        return null;
    }

    const handleSubmit = (values: Hash) => {
        store.saveProject(
            values.name,
            values.keywords,
            values.color,
            transformFeatureFlags(form, featureFlagsOverride),
            JSON.stringify(values.smartIndexSettings)
        );

        if (resetOnSubmit) {
            store.reset();
        }
    };

    const beforeFeatureFlagsUpload = (file: RcFile) => {
        importFeatureFlags(file, form, setFeatureFlagsOverride);
        return false;
    };

    const handleFeatureFlagsOverrideChange = (fieldKey: keyof FeatureFlagsOverride, fieldValue: boolean) => {
        setFeatureFlagsOverride(prevValues => {
            const newValues = { ...prevValues };
            newValues[fieldKey] = fieldValue;
            return newValues;
        });
    };

    const handleSmartIndexSettingsChange = (fieldValue: Partial<SmartIndexSettings>) => {
        if (fieldValue.enabled !== undefined) {
            setInstructWorkflowRequired(fieldValue.enabled);
            form.validateFields();
        }
    };

    const onValuesChange = (value: Object) => {
        if (!value) {
            return;
        }

        const fieldKey = Object.keys(value)[0];
        const fieldValue = Object.values(value)[0];

        if (featureFlagsOverrideKeys.includes(fieldKey)) {
            return handleFeatureFlagsOverrideChange(fieldKey as keyof FeatureFlagsOverride, fieldValue);
        }

        if (fieldKey === 'smartIndexSettings') {
            return handleSmartIndexSettingsChange(fieldValue);
        }
    };

    const colorRadioValues = ['red', 'magenta', 'blue', 'aqua', 'green', 'orange', 'brown', 'black', 'purple', 'yellow'];

    return (
        <Form
            id="edit-project-form"
            data-id="edit-project-form"
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            onValuesChange={onValuesChange}
        >
            <FormItem
                label={<span>Project name</span>}
                name="name"
                initialValue={store.project.title}
                rules={[{ required: true, message: 'Please input project name!', whitespace: true }]}
            >
                <Input className="project-name" size="large"/>
            </FormItem>
            <FormItem
                label={<span>Color</span>}
                initialValue={store.project.color}
                name="color"
            >
                <Radio.Group className="color-radio-group" buttonStyle="solid">
                    {colorRadioValues.map(r => (
                        <Radio.Button key={r} value={r} className={r}>
                            <span className="color-radio" />
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </FormItem>

            <div className="feature-flag-import-export-container">
                {!!store.project.storedFeatureFlags && (
                    <span className="feature-flag-import-export-button export" onClick={store.exportProjectFeautureFlags}>
                        <i className="alpha-icon xs arrow-up" />
                        <span>Export feature flags</span>
                    </span>
                )}
                
                <Upload
                    name="file"
                    maxCount={1}
                    fileList={featureFlagFileList}
                    beforeUpload={beforeFeatureFlagsUpload}
                    onRemove={setProjectFeatureFlags}
                    onChange={info => setFeatureFlagFileList(info.fileList)}
                >
                    <span className="feature-flag-import-export-button">
                        <i className="alpha-icon xs arrow-down" />
                        <span>Import feature flags</span>
                    </span>
                </Upload>
            </div>
   
            <FeatureFlagsRenderer
                featureFlagsOverride={featureFlagsOverride}
                setFeatureFlagsOverride={setFeatureFlagsOverride}
            />

            <SmartIndexSettingsEditor store={store} instructWorkflowRequired={instructWorkflowRequired} />
        </Form>);
};

export default observer(EditProjectForm);