import { FormInstance } from 'antd';
import { DefaultAlphaConfigurations } from './constants';
import { RcFile } from 'antd/lib/upload/interface';
import { FeatureFlags } from './models';
import { isEmpty, isNil } from 'lodash';
import { AlphaConfig } from '../administration/models/AlphaConfig';

export enum FeatureFlagsOverrideKey {
    OverrideFeatureFlags = 'overrideFeatureFlags',
    OverrideImageFeatureFlags = 'overrideImageFeatureFlags',
    OverrideTextFeatureFlags = 'overrideTextFeatureFlags'
}

export type FeatureFlagsOverride = {
    [FeatureFlagsOverrideKey.OverrideFeatureFlags]: boolean;
    [FeatureFlagsOverrideKey.OverrideImageFeatureFlags]: boolean;
    [FeatureFlagsOverrideKey.OverrideTextFeatureFlags]: boolean
};

export const featureFlagsOverrideKeys = Object.values(FeatureFlagsOverrideKey) as string[];

export const getFeatureFlagFormValue = (key: string, value: string): string | boolean => {
    const controlType = DefaultAlphaConfigurations.find(c => c.key === key)?.controlType;

    if (controlType === 'checkbox') {
        return value === 'true';
    }

    return value;
};

const alphaConfigFormDefaults = DefaultAlphaConfigurations.reduce<Record<string, string | boolean>>((keys, config) => {
    keys[config.key] = getFeatureFlagFormValue(config.key, config.defaultValue);
    keys['TEXT::' + config.key] = getFeatureFlagFormValue(config.key, config.defaultValue);
    keys['IMAGE::' + config.key] = getFeatureFlagFormValue(config.key, config.defaultValue);
    return keys;
}, {});

export const transformFeatureFlags = (form: FormInstance, featureFlagsOverride: Partial<FeatureFlagsOverride> = {}) => {
    const obj: Record<string, string | boolean> = {};

    const { overrideFeatureFlags, overrideTextFeatureFlags, overrideImageFeatureFlags } = featureFlagsOverride;

    DefaultAlphaConfigurations.forEach(c => {
        if (!overrideFeatureFlags) {
            form?.resetFields([c.key]);
        }

        if (overrideFeatureFlags) {
            obj['GENERAL::' + c.key] = form.getFieldValue(c.key);
        }

        if (
            overrideTextFeatureFlags &&
            (!overrideFeatureFlags || form.getFieldValue('TEXT::' + c.key) !== obj['GENERAL::' + c.key])
        ) {
            obj['TEXT::' + c.key] = form.getFieldValue('TEXT::' + c.key);
        }

        if (
            overrideImageFeatureFlags &&
            (!overrideFeatureFlags || form.getFieldValue('IMAGE::' + c.key) !== obj['GENERAL::' + c.key])
        ) {
            obj['IMAGE::' + c.key] = form.getFieldValue('IMAGE::' + c.key);
        }
    });

    // Convert all values to string.
    Object.entries(obj).forEach(([key, value]) => {
        obj[key] = isNil(value) ? '' : value.toString();
    });

    // If obj is empty return null.
    return Object.keys(obj).length === 0 ? null : JSON.stringify(obj);
};

export const importFeatureFlags = (
    file: RcFile,
    form: FormInstance,
    onImportSuccess?: (featureFlags: FeatureFlagsOverride) => void
) => {
    if (!form) {
        return;
    }

    const reader = new FileReader();

    reader.onload = e => {
        if (!e.target || typeof e.target.result !== 'string') {
            return;
        }

        const data: { featureFlags: FeatureFlags } | null = JSON.parse(e.target.result);

        if (!data || !data.featureFlags) {
            return;
        }

        const { overrideFeatureFlags, overrideTextFeatureFlags, overrideImageFeatureFlags } = setFormFeatureFlags(
            form,
            data.featureFlags
        );

        if (onImportSuccess) {
            onImportSuccess({
                overrideFeatureFlags,
                overrideTextFeatureFlags,
                overrideImageFeatureFlags
            });
        }
    };

    reader.readAsText(file);
};

export const setFormFeatureFlags = (form: FormInstance, featureFlags?: FeatureFlags | null) => {
    if (!featureFlags) {
        return { overrideFeatureFlags: false, overrideTextFeatureFlags: false, overrideImageFeatureFlags: false };
    }

    setFormFeatureFlagsDefaults(form);

    const overrideFeatureFlags = !isEmpty(featureFlags.general);
    const overrideTextFeatureFlags = !isEmpty(featureFlags.text);
    const overrideImageFeatureFlags = !isEmpty(featureFlags.image);

    if (overrideFeatureFlags) {
        Object.entries(featureFlags.general).forEach(([flag, value]) => {
            form.setFieldsValue({ [flag]: getFeatureFlagFormValue(flag, value) });
        });
    }

    if (overrideTextFeatureFlags) {
        Object.entries(featureFlags.text).forEach(([flag, value]) => {
            form.setFieldsValue({ [`TEXT::${flag}`]: getFeatureFlagFormValue(flag, value) });
        });
    }

    if (overrideImageFeatureFlags) {
        Object.entries(featureFlags.image).forEach(([flag, value]) => {
            form.setFieldsValue({ [`IMAGE::${flag}`]: getFeatureFlagFormValue(flag, value) });
        });
    }

    return { overrideFeatureFlags, overrideTextFeatureFlags, overrideImageFeatureFlags };
};

export const setFormFeatureFlagsDefaults = (form: FormInstance) => {
    form.setFieldsValue({ ...alphaConfigFormDefaults });
};

export const convertAlphaConfigsToFeatureFlags = (configs: AlphaConfig[]): FeatureFlags => {
    return configs.reduce<FeatureFlags>(
        (acc, config) => {
            acc.general[config.name] = config.value;
            acc.text[config.name] = config.value;
            acc.image[config.name] = config.value;
            return acc;
        },
        {
            general: {},
            text: {},
            image: {}
        }
    );
};
