import * as React from 'react';

export default (containerRef: React.RefObject<HTMLDivElement>, offset: number = 0) => {
    const [height, setHeight] = React.useState<number>(800);

    React.useEffect(() => {
        const container = containerRef.current;

        if (!container) {
            return;
        }

        const adjustHeight = () => {
            const rendererTopPosition = container.getBoundingClientRect().top ?? 0;
            setHeight(window.innerHeight - rendererTopPosition - offset);
        };

        adjustHeight();

        const resizeObserver = new ResizeObserver(() => {
            adjustHeight();
        });

        if (container) {
            resizeObserver.observe(container);
        }

        window.addEventListener('resize', adjustHeight);

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }

            window.removeEventListener('resize', adjustHeight);
        };
    }, [containerRef, offset]);

    return { height };
};
