import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import _ from 'lodash';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PackageLine } from '../../common/models';
import { getDetectorAutocompleteOptions } from '../misc/FormTypesUtils';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore
};

const FormPartCreateDialog: React.FC<Props> = ({store, visualStore}) => {
    const [form] = Form.useForm();
    const [autocompleteOptions, setAutocompleteOptions] = React.useState<{value: string; label: string}[]>([]);

    React.useEffect(() => {
        initFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.refernceFormPart]);

    React.useEffect(() => {
        const options = store.packageLines.map(getDetectorAutocompleteOptions);
        setAutocompleteOptions(_.uniqBy(options, 'value'));
    }, [store.packageLines]);

    const initFormData = () => {
        if (!store.refernceFormPart) {
            return;
        }

        const { coordinatesReference } = store.refernceFormPart;
        const packageLine = coordinatesReference 
            ? store.packageLines.find(pl => 
                pl.normalizedText === coordinatesReference.phrase.text &&
                pl.coordinates?.pageHeight === coordinatesReference.height &&
                pl.coordinates?.pageWidth === coordinatesReference.width)
            : undefined;

        if (packageLine == null) {
            initPackageLineValue();
        }

        form.setFieldsValue({
            name: store.refernceFormPart.name + '(copy)',
            detectorParts: store.refernceFormPart.detector?.detectorParts ?? [],
            ignoreParts: store.refernceFormPart.detector?.ignoreParts ?? [],
            packageLineId: packageLine?.id
        });
    };

    const initPackageLineValue = () => {
        reaction(() => store.isLoadingPackageLines, (isLoadingPackageLines, r) => {
            if (!isLoadingPackageLines && store.refernceFormPart) {
                const { coordinatesReference } = store.refernceFormPart;
                const packageLine = coordinatesReference 
                    ? store.packageLines.find(pl => 
                        pl.normalizedText === coordinatesReference.phrase.text &&
                        pl.coordinates?.pageHeight === coordinatesReference.height &&
                        pl.coordinates?.pageWidth === coordinatesReference.width)
                    : undefined;
                form.setFieldsValue({
                    packageLineId: packageLine?.id
                });
                r.dispose();
            }
        });
    };

    const handleCancel = () => {
        visualStore.setIsFormPartCreateDialogVisible(false);
        store.setRefernceFormPart(undefined);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            const createdSuccessfully = await store.createFormPart(
                values.name, values.detectorParts, values.ignoreParts, values.packageLineId
            );

            if (createdSuccessfully) {
                handleCancel();
            }
        }).catch(() => {
            // do nothing
        });
    };

    const validateName = (name: string) => {
        const currentPart = store.formParts.find(part => part.name === name && part.formVariationId === store.selectedFormVariation);

        if (currentPart) {
            return Promise.reject('Form part with such name already exists');
        }

        return Promise.resolve();
    };

    const getPackageLineLabel = (pl: PackageLine) => {
        const label = `${pl.text ?? ''} [${pl.coordinates?.x ?? ''}, ${pl.coordinates?.y ?? ''}] (${pl.coordinates?.page})`;

        return {
            title: label, 
            label: label,
            value: pl.id
        };
    };

    return (
        <Modal
            wrapProps={{'data-id': 'New form part'}}
            className="alpha-portal-dialog two-columns"
            title="New form part"
            visible={visualStore.isFormPartCreateDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button data-id="form-part-add-dialog-cancel" className="light" key="back" size="large" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button 
                    data-id="form-part-add-dialog-submit" 
                    key="submit" 
                    size="large" 
                    type="primary" 
                    onClick={handleSubmit}
                    disabled={store.isLoadingPackageLines}
                >
                    Add form part
                </Button>
            ]}
        >
            {store.isLoadingPackageLines && (
                <div className="dialog-loading-overlay">
                    <Spin />
                </div>
            )}
            <Form form={form} layout="vertical">
                <Form.Item 
                    name="name" 
                    label="Name" 
                    rules={[
                        {required: true, message: 'Provide name for the form part, please'},
                        {validator: (__, name) => validateName(name)}
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    rules={[{required: true, message: 'Provide detector parts, please'}]}
                    name="detectorParts" 
                    label="Detector parts"
                >
                    <Select options={autocompleteOptions} mode="tags" showSearch />
                </Form.Item>
                <Form.Item 
                    name="ignoreParts" 
                    label="Ignore parts"
                >
                    <Select options={autocompleteOptions} mode="tags" showSearch />
                </Form.Item>
                <Form.Item 
                    rules={[{required: true, message: 'Provide detector coordinates reference package line, please'}]} 
                    name="packageLineId" 
                    label="Coordinates reference package line"
                >
                    <Select 
                        loading={store.isLoadingPackageLines}
                        options={store.packageLines.filter(pl => pl.blockType === 'HORIZONTAL_LINE_BLOCK').map(getPackageLineLabel)}
                        showSearch
                        filterOption={(input, option) => option?.title && option.title.toLowerCase().includes(input.toLowerCase())}   
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormPartCreateDialog);