import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import { SearchHistoryFilter, SearchHistoryResult } from './types';

export default class HistoryService {
    async searchHistory(filter: SearchHistoryFilter) {
        const result = await execQuery<SearchHistoryResult>({
            query: gql`
                query SearchHistory($filter: SearchHistoryFilter!) {
                    searchHistory(filter: $filter) {
                        historyRecords {
                            id
                            entityId
                            entityName
                            action
                            createDate
                            fields
                            objectName
                            projectId
                            userId
                            userName
                            meta
                        }
                        total
                    }
                }
            `,
            variables: { filter },
            fetchPolicy: 'network-only'
        });

        return {
            historyRecords: result.data.searchHistory.historyRecords,
            total: result.data.searchHistory.total
        };
    }
}
