import { observable } from 'mobx';
import { ElasticSearchMatchPhraseRuleResult, RuleError } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import TagRuleBase from './TagRuleBase';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class ElasticSearchMatchPhraseRule extends TagRuleBase<ElasticSearchMatchPhraseRule, ElasticSearchMatchPhraseRuleResult> {
    @observable
    query: string;

    @observable
    slope: number;

    @observable
    excludedBlockTypes: string[];

    constructor(
        id: string | null,
        projectId: string | null,
        name: string,
        tagId: string | null,
        tag: string,
        query: string,
        slope: number,
        pipeline: PipelineStep<PipelineStepType>[] = [],
        groupId: string | null,
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        excludedBlockTypes: string[] = [],
        errors?: RuleError[]
    ) {
        super(
            id,
            projectId,
            name,
            tagId,
            tag,
            'ElasticSearchMatchPhraseRule',
            pipeline,
            groupId,
            updateDate,
            priority,
            description,
            state,
            status,
            overridePriority,
            errors
        );
        this.query = query;
        this.slope = slope;
        this.excludedBlockTypes = excludedBlockTypes || [];
    }

    clone(): ElasticSearchMatchPhraseRule {
        // eslint-disable-next-line max-len
        return new ElasticSearchMatchPhraseRule(this.id, this.projectId, this.name, this.tagId, 
            this.tag, this.query, this.slope, this.clonePipeline(), this.groupId, this.updateDate, 
            this.priority, this.description, this.state, this.status, this.overridePriority, this.excludedBlockTypes);
    }

    validateState(): string[] {
        if (!this.name || !this.query || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): ElasticSearchMatchPhraseRuleResult {
        const json: ElasticSearchMatchPhraseRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,
            slope: this.slope,
            query: this.query,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority,
            excludedBlockTypes: this.excludedBlockTypes
        };

        return json;
    }
}