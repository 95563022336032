/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, runInAction, computed } from 'mobx';
import _ from 'lodash';
import { Dictionary } from 'lodash';
import { RulesStore } from '.';
import { TagName } from './RulesStore';
import { MLModel } from '../../ml_storage/types';
import { Connection } from '../models';
import { ConnectionsDefinition } from '../../iota_connections/types';
import type { TagModel } from '../models/TagModel';
import type { RuleName, ImportMapperTableModel, RulesToUploadData, RulesToUploadDataForIotaImport, RulesToUploadDataForProjectBindingsImport } from '../types';
import { message } from 'antd';
import { TagsGroupModel } from '../models/TagsGroupModel';
import TagsGroupVisualStore from './TagsGroupVisualStore';
import { ErrorStore } from '../../common/stores';

type TagGroupDict = {
    [key: string]: string | null
};

type TagActionDict = {
    [key: string]: 'Replace' | 'Add'
};

type CommitedImportRuleResult = {
    [key: string]: string
};

export default class RulesImportVisualStore {
    @observable
    isLoading: boolean;

    @observable
    selectedRulesKeys: string[] = [];

    @observable
    selectedTagKeys: string[] = [];

    @observable
    selectedRulesKeysByTag: string[] = [];

    @observable
    rules: Dictionary<RuleName[]>;

    @observable
    tags: TagName[];

    generatedFileName: string = '';

    @observable
    tagGroupDict: TagGroupDict = {};

    assignedGroupName: string | null;

    @observable
    mlModels: MLModel[] = [];

    @observable
    redDataConnections: Connection[] = [];

    @observable
    iotaConnections: ConnectionsDefinition[] = [];

    @observable
    tagActionDict: TagActionDict = {};

    commitedImportRuleResult: CommitedImportRuleResult = {};

    @observable
    isMapperTableVisible: boolean = false;

    mapperTableData: ImportMapperTableModel[];

    @observable
    isDialogVisible: boolean = false;

    @observable
    importConfirmationDialogVisible: boolean = false;

    @observable
    expandedKeys: string[] = [];

    @observable
    tagsWithDuplicatedRules: string[] = [];

    @observable
    duplicatedRules: string[] = [];

    exportedIotaFileName: string;
    
    iotaAppGroupId: string;

    iotaAppRootGroupId?: string;

    exportedProjectFieldsFileName: string;

    @computed
    get IsRuleSelected() {
        return !!(_.union(this.selectedRulesKeys, this.selectedRulesKeysByTag)).length;
    }

    @computed
    get AllGroupsSelected() {
        if (this.exportedIotaFileName) {
            return Object.values(this.tagGroupDict).find(v=> v === null) === undefined;
        }
        const tags = this.getTagsForRules( _.union(this.selectedRulesKeys, this.selectedRulesKeysByTag));
        const groupIds = tags.map(t => this.tagGroupDict[t]);
        return !(groupIds.find(t => t === null) === null);
    }

    @computed
    get currentProject() {
        return this.store.currentProject;
    }

    constructor(private store: RulesStore, private tagsGroupStore: TagsGroupVisualStore, private errorStore: ErrorStore) {
    }

    @action
    async setTableData(data: RulesToUploadData, dropIndex?: number ) { 
        this.mapperTableData = data.mapperTableData ?? [];
        const groups = this.getGroups();
        await this.store.setRuleTags();
        this.assignedGroupName = typeof dropIndex === 'number' ? groups![dropIndex].name : null;
        this.generatedFileName = data.generatedFileName;
        this.rules = data.importedRules ?? [];
        runInAction(() => {
            this.tags = _.keys(this.rules).map( d => {
                return ({name: d}); 
            } ); 
            this.tagGroupDict = {};
            this.tagActionDict = {};
            this.selectedRulesKeys = [];
            this.selectedRulesKeysByTag = [];
            this.tags.forEach(t => {
                const group = this.getGroupIfExists(t.name);

                if (!group) {
                    this.tagGroupDict[t.name] = this.store.tagsGroups.find(g => g.name === this.assignedGroupName)?.id || null;
                    this.tagActionDict[t.name] = 'Add';
                } else {
                    this.tagGroupDict[t.name] = this.store.tagsGroups.find(g => g.name === group.name)!.id;
                    const tagHasProblems = this.tagHasProblems(t.name);
                    this.tagActionDict[t.name] = tagHasProblems ? 'Add' : 'Replace';
                }
            });

        });

        this.setIsDialogVisible(true); 
        if (data.projectId) {
            this.setMapperTableIsVisible(true);
        }
    }

    isActionDisabled(tagName: string) {
        return !this.getGroupIfExists(tagName) || this.tagHasProblems(tagName);
    }

    async setTableDataForIotaImport(data: RulesToUploadDataForIotaImport, iotaAppGroupId: string, iotaAppRootGroupId?: string) { 
        this.exportedIotaFileName = data.exportedIotaFileName;
        this.iotaAppGroupId = iotaAppGroupId;
        this.iotaAppRootGroupId = iotaAppRootGroupId;
        this.setTableData(data.ruleData);
    }

    async setTableDataForProjectFieldsImport(data: RulesToUploadDataForProjectBindingsImport) { 
        this.exportedProjectFieldsFileName = data.exportedBindingsFileName;
        this.setTableData(data.ruleData);
    }

    @action
    setIsDialogVisible(isVisible: boolean) {
        this.isDialogVisible = isVisible;
    }

    @action
    setMapperTableIsVisible(isVisible: boolean) {
        this.isMapperTableVisible = isVisible;
    }

    @action
    setExpandedKeys(expanded: boolean, record: TagModel) {
        if (expanded) {
            this.expandedKeys.push(record.name);
        } else {
            this.expandedKeys = this.expandedKeys.filter(k => k !== record.name!);
        }
    }

    @action.bound
    setImportConfirmationDialogVisible(importConfirmationDialogVisible: boolean) {
        this.importConfirmationDialogVisible = importConfirmationDialogVisible;
    }

    @action.bound
    async importRules(importCallback: () => Promise<void>) {
        if (!this.currentProject) {
            return;
        }
        this.setIsDialogVisible(false);
        this.isLoading = true;
        const idsArray = this.exportedIotaFileName || this.exportedProjectFieldsFileName 
            ? _.flatten(Object.values(this.rules)).map(r=> r.id) 
            : _.union(this.selectedRulesKeys, this.selectedRulesKeysByTag);
        const ruleGroupDict = this.getRuleGroupDict(idsArray);
        const mappingData = this.mapperTableData.map(x => {
            return {id: x.id, exportedName: x.name, nativeName: x.bestValue!, tableType: x.tableType}; 
        });

        let commitAction = `${process.env.REACT_APP_MANAGE_URL}projects/${this.currentProject.id}/rules/${this.generatedFileName}/import/commit`;

        if (this.exportedIotaFileName != null && this.exportedIotaFileName.trim() !== '') {
            // eslint-disable-next-line max-len
            commitAction = `${process.env.REACT_APP_MANAGE_URL}projects/${this.currentProject.id}/iota/applications/${this.generatedFileName}/${this.exportedIotaFileName}/import/${this.iotaAppGroupId}/commit`;

            if (this.iotaAppRootGroupId) {
                commitAction += `?rootGroupId=${this.iotaAppRootGroupId}`;
            }
        } else if (this.exportedProjectFieldsFileName) {
            commitAction = `${process.env.REACT_APP_MANAGE_URL}projectFields/${this.currentProject.id}/${this.generatedFileName}/${this.exportedProjectFieldsFileName}/commit`;
        }

        const result = await this.store.commitImportedRules(ruleGroupDict, this.tagActionDict, mappingData, commitAction);
        if (result.isErr()) {
            runInAction(() => this.isLoading = false);
            result.mapErr(e => this.errorStore.addBasicError(new Error(e.data?.title ?? e.text)));
        }

        const promises = [
            this.store.getRules(), 
            this.store.getTagsGroups(), 
            this.tagsGroupStore.loadTagsGroups(), 
            this.store.getProjectTagsVersion()] as 
            [
                Promise<void>, 
                Promise<TagsGroupModel[]>, 
                Promise<void>, 
                Promise<void>
            ];

        await Promise.all(promises);
        this.resetImportParams();
        await importCallback();
       
        runInAction(() => this.isLoading = false);
    }

    @action.bound
    handleBeforeUpload(e?: React.MouseEvent<HTMLElement>) {
        if (!this.store.tagsGroups.length) {
            if (e) {
                e.stopPropagation();
            }
            message.warning('Please create at least one rule tag group to use import operation');
            return false;
        } else {
            return true;
        }
    }

    @action
    handleRulesSelection(isChecked: boolean, recordId: string) {
        if (isChecked) {
            this.selectedRulesKeys.push(recordId);
        } else {
            this.selectedRulesKeys = this.selectedRulesKeys.filter(s => s !== recordId);
        }
    }

    setSelectedTagKeys(tags: string[]) {
        this.selectedTagKeys = tags;
    }

    @action
    handleTagsSelection(selectedKeys: string[]) {
        this.setSelectedTagKeys(selectedKeys);
        runInAction(() => this.selectedRulesKeysByTag = []);
        selectedKeys.forEach( (s: string | number)   => { 
            this.rules[s].forEach(d => this.selectedRulesKeysByTag.push(d.id!)); 
        });
        this.selectedRulesKeys = this.selectedRulesKeys.filter(s => this.selectedRulesKeysByTag.indexOf(s) < 0);
    }

    updateMapperTableData(val: string, name: string) {
        const index =  this.mapperTableData.findIndex(x => x.name === name);
        this.mapperTableData[index].bestValue = val;
    }

    getRules() {
        return this.store.rules;
    }

    getGroups() {
        return this.store.tagsGroups;
    }

    getRuleTags() {
        return this.store.ruleTags;
    }

    getGroupIfExists  (tagName: string | null)  {
        if (!tagName) {
            return null;
        }

        const tag = this.store.ruleTags.find(t => t.name === tagName);
        if (tag) {
            const group = this.store.tagsGroups.find(x => x.id ===  tag.groupId);
            return group;
        } 
        return null;
    }

    @action.bound
    handleGroupSelection(tag: string | null, key: string | null) {
        if (tag == null) {
            for (let tagKey of this.tags.map(t => t.name)) {
                const groupName = this.getGroupIfExists(tagKey)?.name || this.assignedGroupName;
                if (groupName != null) {
                    continue;
                }
                this.tagGroupDict[tagKey] = key;
            }

            const tags = [...this.tags];
            this.tags = tags;
        } else {
            this.tagGroupDict[tag] = key;
        }
    }

    @action.bound
    handleActionSelection(tag: string, key: 'Add' | 'Replace') {
        this.tagActionDict[tag] = key;
    }

    handleImportClick = (callBack: () => Promise<void>, showImportConfirmationDialog?: boolean) => {
        if (!this.isMapperTableVisible) {
            this.checkForDuplicateNames();
            if (this.tagsWithDuplicatedRules.length) {
                return;
            }
            this.setMapperTableIsVisible(true);
            return;
        }

        if (this.store.ruleTags.length && showImportConfirmationDialog) {
            this.setImportConfirmationDialogVisible(true);
        } else {
            this.importRules(callBack);
        }
    };

    private checkForDuplicateNames = () => {
        const existingRules = this.getRules().map(r=> r.name);
        const tagsWithDuplicatedRules = [] as string[];
        let duplicatedRules = [] as string[];

        Object.keys(this.rules).forEach(tag=> {
            if (this.rules[tag].some(r=> existingRules.includes(r.name)) && this.tagActionDict[tag] === 'Add') {
                const filteredRules = this.rules[tag].filter(r=> this.selectedRulesKeys.includes(r.id) || this.selectedRulesKeysByTag.includes(r.id)).map(r=> r.name);
                duplicatedRules = duplicatedRules.concat(filteredRules);
                if (filteredRules.length) {
                    tagsWithDuplicatedRules.push(tag);
                }
            }
        });
        this.tagsWithDuplicatedRules = tagsWithDuplicatedRules;
        this.duplicatedRules = duplicatedRules;
    };
    
    setSeletedRules(rules: string[]) {
        this.selectedRulesKeys = rules; 
    }

    @action.bound
    resetImportParams() {
        this.setIsDialogVisible(false);
        this.setMapperTableIsVisible(false);
        this.exportedIotaFileName = '';
        this.exportedProjectFieldsFileName = '';
        this.tagActionDict = {};
        this.tagGroupDict = {};
        this.setSeletedRules([]);
        this.setSelectedTagKeys([]);
        this.selectedRulesKeysByTag = [];
        this.tagsWithDuplicatedRules = [];
        this.duplicatedRules = [];
    }

    private getRuleGroupDict(idsArray: string[]) {
        const tags = this.getTagsForRules(idsArray);
        const ruleGroupDict = {};
        idsArray.map((r, index) => ruleGroupDict[r] = this.tagGroupDict[tags[index]]);
        return ruleGroupDict;
    }

    private getTagsForRules(ruleIds: string[]) {
        return ruleIds.map(id => Object.keys(this.rules).find(key => this.rules[key].find(r => r.id === id))) as string[];
    }

    private tagHasProblems(tagName: string) {
        return !!this.rules[tagName].map(x => x.id).filter(y => !!this.commitedImportRuleResult[y]).length;
    }

}