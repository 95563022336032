import { action, observable } from 'mobx';
import { DefaultAlphaConfigurations } from '../../common/constants';
import { FeatureFlagTreeNodeModel, TreeFeatureFlagModel } from '../models';
import { AlphaConfigurationControlType } from '../../common/types';

const controlTypeOrder: Record<AlphaConfigurationControlType, number> = { checkbox: 1, text: 2, textarea: 3 };

export default class FeatureFlagsTreeStore {
    @observable
    nodes: FeatureFlagTreeNodeModel[] = [];

    @observable
    expandedKeys: string[] = [];

    readonly featureFlagsKeyDelimiter = ':';

    constructor() {
        this.nodes = this.createFeatureFlagNodes();
        this.setExpandedKeys(this.getAllNodeKeys(this.nodes));
    }

    @action.bound
    setExpandedKeys(expandedKeys: string[]) {
        this.expandedKeys = expandedKeys;
    }

    @action.bound
    toggleExpanded(key: string) {
        if (this.expandedKeys.includes(key)) {
            this.setExpandedKeys([...this.expandedKeys.filter(expandedKey => expandedKey !== key)]);
        } else {
            this.setExpandedKeys([...this.expandedKeys, key]);
        }
    }

    private createFeatureFlagNodes() {
        const nodes: FeatureFlagTreeNodeModel[] = [];

        const sortedConfigurations = DefaultAlphaConfigurations.slice()
            .sort((a, b) => controlTypeOrder[a.controlType] - controlTypeOrder[b.controlType])
            .sort(
                (a, b) =>
                    a.key.split(this.featureFlagsKeyDelimiter).length -
                    b.key.split(this.featureFlagsKeyDelimiter).length
            );

        sortedConfigurations.forEach((config, configIndex) => {
            const keyParts = config.key.split(this.featureFlagsKeyDelimiter);
            let currentNode: FeatureFlagTreeNodeModel | undefined;

            keyParts.forEach((keyPart, keyPartIndex) => {
                const nodeKey = `${configIndex}_${keyPart}`;

                if (keyPartIndex === 0) {
                    currentNode = nodes.find(node => node.keyPart === keyPart);

                    if (!currentNode) {
                        currentNode = new FeatureFlagTreeNodeModel(nodeKey, keyPart);
                        nodes.push(currentNode);
                    }
                } else {
                    if (currentNode) {
                        let childNode = currentNode.children.find(child => child.keyPart === keyPart);

                        if (!childNode) {
                            childNode = new FeatureFlagTreeNodeModel(nodeKey, keyPart);
                            currentNode.children.push(childNode);
                        }

                        currentNode = childNode;
                    }
                }

                if (keyPartIndex === keyParts.length - 1 && currentNode) {
                    const featureFlag = new TreeFeatureFlagModel(config.key, keyPart, config.controlType);
                    currentNode.setFeatureFlag(featureFlag);
                }
            });
        });

        return nodes;
    }

    private getAllNodeKeys(allNodes: FeatureFlagTreeNodeModel[]) {
        const keys: string[] = [];

        const traverse = (nodes: FeatureFlagTreeNodeModel[]): void => {
            nodes.forEach(node => {
                keys.push(node.key);

                if (node.children) {
                    traverse(node.children);
                }
            });
        };

        traverse(allNodes);

        return keys;
    }
}
