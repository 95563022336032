import { Button, Checkbox, Collapse, Form, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FeatureFlags } from '../../common/models';
import { UploadedPackagesVisualStore } from '../stores';
import { FeatureFlagsTreeRenderer } from '../../common/components';
import {
    transformFeatureFlags,
    setFormFeatureFlags,
    FeatureFlagsOverride,
    convertAlphaConfigsToFeatureFlags
} from '../../common/FeatureFlagsHelpers';

type Props = {
    visible: boolean;
    changeVisible: (visible: boolean) => void;
    currentFeatureFlags: FeatureFlags;
    currentPackageId: string;
    store: UploadedPackagesVisualStore
};

const FeatureFlagsModal: React.FC<Props> = ({
    visible,
    changeVisible,
    currentFeatureFlags,
    store,
    currentPackageId
}) => {
    const [form] = Form.useForm();
    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);
    const [featureFlagsOverride, setFeatureFlagsOverride] = React.useState<FeatureFlagsOverride>({
        overrideFeatureFlags: false,
        overrideImageFeatureFlags: false,
        overrideTextFeatureFlags: false
    });

    React.useEffect(() => {
        if (visible) {
            setFeatureFlags();
        } else {
            setFeatureFlagsOverride({
                overrideFeatureFlags: false,
                overrideImageFeatureFlags: false,
                overrideTextFeatureFlags: false
            });
            setOpenPanelKeys([]);
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const handleSubmit = () => {
        const featureFlags = transformFeatureFlags(form, featureFlagsOverride);
        store.updatePackageFeatureFlags(currentPackageId, featureFlags).then(result => {
            if (result) {
                changeVisible(false);
            }
        });
    };

    const handleCancel = () => {
        changeVisible(false);
    };

    const hasModifiedFeatureFlags = React.useMemo(
        () => Object.values(currentFeatureFlags).some(flag => Object.keys(flag).length > 0),
        [currentFeatureFlags]
    );

    const setFeatureFlags = () => {
        if (!hasModifiedFeatureFlags) {
            setFormFeatureFlags(form, convertAlphaConfigsToFeatureFlags(store.defaultAlphaConfigs));
            return;
        }
        
        setFeatureFlagsOverride({
            ...setFormFeatureFlags(form, currentFeatureFlags)
        });
    };

    const featureFlagsPanelExtra = (onCheckboxChange: (e: CheckboxChangeEvent) => void, checked: boolean) => {
        return(
            <div 
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <label>
                    Override flags:
                    <Checkbox 
                        style={{marginLeft: 12}}
                        checked={checked}
                        onChange={onCheckboxChange}
                    />
                </label>
            </div>
        );
    };

    return (
        <Modal
            title="Feature flags"
            className="alpha-portal-dialog two-columns"
            visible={visible}
            centered
            maskClosable={false}
            closable={false}
            width={600}
            footer={[
                <Button
                    key="feature-flags-dialog-close"
                    data-id="feature-flags-dialog-close"
                    className="light"
                    size="large"
                    onClick={handleCancel}
                >
                    Close
                </Button>,
                <Button
                    key="feature-flags-dialog-save"
                    data-id="feature-flags-dialog-save"
                    type="primary"
                    size="large"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            ]}
            onCancel={handleCancel}
        >
            <>
                {hasModifiedFeatureFlags && (
                    <div className="feature-flag-import-export-container">
                        <span
                            className="feature-flag-import-export-button export"
                            onClick={() => store.exportPackageFeautureFlags(currentPackageId)}
                        >
                            <i className="alpha-icon xs arrow-up" />
                            <span>Export feature flags</span>
                        </span>
                    </div>
                )}

                <Form form={form} layout="vertical">
                    <Collapse activeKey={openPanelKeys} onChange={key => setOpenPanelKeys(key as string[])}>
                        <Collapse.Panel
                            key="1"
                            header="General feature flags"
                            forceRender
                            extra={featureFlagsPanelExtra(e => {
                                setFeatureFlagsOverride(prev => ({ ...prev, overrideFeatureFlags: e.target.checked }));
                            }, featureFlagsOverride.overrideFeatureFlags)}
                        >
                            <FeatureFlagsTreeRenderer disableInputs={!featureFlagsOverride.overrideFeatureFlags} />
                        </Collapse.Panel>
                        <Collapse.Panel
                            key="2"
                            header="Text feature flags"
                            forceRender
                            extra={featureFlagsPanelExtra(e => {
                                setFeatureFlagsOverride(prev => ({
                                    ...prev,
                                    overrideTextFeatureFlags: e.target.checked
                                }));
                            }, featureFlagsOverride.overrideTextFeatureFlags)}
                        >
                            <FeatureFlagsTreeRenderer
                                disableInputs={!featureFlagsOverride.overrideTextFeatureFlags}
                                featureFlagKeyPrefix="TEXT::"
                            />
                        </Collapse.Panel>
                        <Collapse.Panel
                            key="3"
                            header="Images feature flags"
                            forceRender
                            extra={featureFlagsPanelExtra(e => {
                                setFeatureFlagsOverride(prev => ({
                                    ...prev,
                                    overrideImageFeatureFlags: e.target.checked
                                }));
                            }, featureFlagsOverride.overrideImageFeatureFlags)}
                        >
                            <FeatureFlagsTreeRenderer
                                disableInputs={!featureFlagsOverride.overrideImageFeatureFlags}
                                featureFlagKeyPrefix="IMAGE::"
                            />
                        </Collapse.Panel>
                    </Collapse>
                </Form>
            </>
        </Modal>
    );
};

export default observer(FeatureFlagsModal);
