import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { HotKeys } from 'react-hotkeys';
import { ChatbotStore, InteractiveLabelsVisualStore } from '../stores';
import { STORE_PROJECT_INTERACTIIVE_LABELS, STORE_INTERACTIVE_LABELS_CHATBOT } from '../constants';
import { PreviewContent } from '../../common/components/PreviewContent';
import PackageProblemMarker from '../../common/components/PackageProblemMarker';
import { Button, Layout, List } from 'antd';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import { InteractiveLabelerFilter, Chatbot } from '../components';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

const { Content } = Layout;
type Props = {
    [STORE_PROJECT_INTERACTIIVE_LABELS]: InteractiveLabelsVisualStore;
    [STORE_INTERACTIVE_LABELS_CHATBOT]: ChatbotStore
};

export class InteractiveLabelsPage extends React.Component<Props, object> {
    static map = {
        'enableSearch': 'alt+shift+f',
        'disableSearch': 'esc'
    };
    private handlers: {};

    constructor(props: Props) {
        super(props);       
        this.handlers = {'enableSearch': this.togglePopUp.bind(this, true),
            'disableSearch': this.togglePopUp.bind(this, false)};

        this.getContent = this.getContent.bind(this);
    }

    componentDidMount() {
        const store = this.props.projectInteractiveLabelsUI!;
        const chatbotStore = this.props.interactiveLabelsChatbotUI!;

        const queryParams = new URLSearchParams(window.location.search);
        const packageId = queryParams.get('package_id');
        const externalWindowMode = queryParams.get('external_window_mode');
        const openAskAlpha = queryParams.get('open_ask_alpha');

        if (packageId) {
            store.setPreSelectedPackageId(packageId);
        }

        if (externalWindowMode === 'true') {
            store.setExternalWindowMode(true);
            store.setLoading(true);
            document.body.classList.add('external-window-mode');
        }

        if (openAskAlpha === 'true') {
            chatbotStore.setDialogVisible(true);
        }
    }

    togglePopUp = (state: boolean) => {
        this.props.projectInteractiveLabelsUI!.togglePopUp(state);
    };

    getContent = () => {
        const store = this.props.projectInteractiveLabelsUI;

        if (!store) {
            return null;
        }

        const fileExtension = store.selectedPackage && store.selectedPackage.name.split('.').pop();
        if (fileExtension && ['xlsx', 'xls', 'xlsm', 'txt'].indexOf(fileExtension) !== -1) {
            const sortedLines = store.lines.sort((a, b) => (a.coordinates?.page ?? 0) - (b.coordinates?.page ?? 0) || a.rowId - b.rowId);
            return (
                <>
                    <PackageProblemMarker projectInteractiveLabelsUI={this.props.projectInteractiveLabelsUI!!}/>
                    <List
                        style={{width: 'calc(100vw - 300px)', marginTop: 64, maxHeight: 'calc(100vh - 64px)', overflow: 'auto'}}
                        dataSource={sortedLines}
                        renderItem={(item) => (
                            <List.Item>
                                <div style={{whiteSpace: 'pre'}}>{item.text}</div>
                            </List.Item>
                        )}
                    />
                </>
            );
        }

        return (
            <>
                <PackageProblemMarker projectInteractiveLabelsUI={this.props.projectInteractiveLabelsUI!!}/>
                <PreviewContent store={this.props.projectInteractiveLabelsUI!} renderBlocks pageHeaderHeight={270}/>
            </>
        );
    };

    renderOverlay() {
        return (
            <div className="interactive-labels-loading-overlay">
                <LoadingIndicator />
            </div>
        );
    }

    render() {
        const store = this.props.projectInteractiveLabelsUI!;
        return (
            <>
                {store.externalWindowMode && store.loading && this.renderOverlay()}
                <HotKeys keyMap={InteractiveLabelsPage.map} handlers={this.handlers}>
                    <FlexContentContainer
                        content={
                            <>
                                <Layout
                                    className="screen-size"
                                    style={{
                                        height: '100%',
                                        background: 'white',
                                        overflow: 'hidden',
                                        paddingTop: store.externalWindowMode ? 20 : undefined
                                    }}
                                >
                                    <LayoutHeader  
                                        subtitle={Utils.getSubtitle(store.project)}
                                        title="Interactive labels" 
                                        helpMessage="Dynamic tools for real-time interaction with labeled documents in project"
                                        buttons={[
                                            <Button key="clearFilters" onClick={store.clearFilters} size="large" className="light">
                                                Clear filters
                                            </Button>
                                        ]}
                                    />  
                                    <Layout>         
                                        <Content>
                                            <InteractiveLabelerFilter projectInteractiveLabelsUI={store}/>
                                            {this.getContent()}
                                        </Content>
                                    </Layout>

                                    {store.selectedPackage && (
                                        <Chatbot store={this.props.interactiveLabelsChatbotUI!} />
                                    )}
                                </Layout>            
                            </>
                        }
                        contentCssClass="unset"
                    />
                </HotKeys>
            </>
        );
    }
}

export default inject(STORE_PROJECT_INTERACTIIVE_LABELS, STORE_INTERACTIVE_LABELS_CHATBOT)(observer(InteractiveLabelsPage));
