import { Input, Form, Radio, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import * as React from 'react';
import { useForm } from 'antd/lib/form/Form';
import FeatureFlagsRenderer from '../../common/components/FeatureFlagsRenderer';
import {
    transformFeatureFlags,
    importFeatureFlags,
    featureFlagsOverrideKeys,
    setFormFeatureFlagsDefaults,
    FeatureFlagsOverride
} from '../../common/FeatureFlagsHelpers';

const FormItem = Form.Item;

type AddProjectFormProps = {
    onAddProject: (name: string, type: string, keywords: string[], color: string, featureFlags: string | null) => void;
    lockSubmit: boolean;
    onSubmitCallback?: () => void
};

interface Hash {
    [details: string]: string & string[]
}

export const AddProjectForm: React.FC<AddProjectFormProps> = ({ onAddProject, onSubmitCallback }) => {
    const [form] = useForm();

    const [featureFlagsOverride, setFeatureFlagsOverride] = React.useState<FeatureFlagsOverride>({
        overrideFeatureFlags: false,
        overrideImageFeatureFlags: false,
        overrideTextFeatureFlags: false
    });

    const resetFeatureFlags = () => {
        setFormFeatureFlagsDefaults(form);
        setFeatureFlagsOverride({
            overrideFeatureFlags: false,
            overrideImageFeatureFlags: false,
            overrideTextFeatureFlags: false
        });
    };

    const handleFeatureFlagsOverrideChange = (fieldKey: keyof FeatureFlagsOverride, fieldValue: boolean) => {
        setFeatureFlagsOverride(prevValues => {
            const newValues = { ...prevValues };
            newValues[fieldKey] = fieldValue;
            return newValues;
        });
    };

    const handleSubmit = (values: Hash) => {
        onAddProject(
            values.name,
            values.type,
            values.keywords,
            values.color,
            transformFeatureFlags(form, featureFlagsOverride)
        );

        if (onSubmitCallback) {
            onSubmitCallback();
        }

        resetFeatureFlags();
    };

    const beforeFeatureFlagsUpload = (file: RcFile) => {
        importFeatureFlags(file, form, setFeatureFlagsOverride);
        return false;
    };

    const onValuesChange = (value: Object) => {
        if (!value) {
            return;
        }

        const fieldKey = Object.keys(value)[0];
        const fieldValue = Object.values(value)[0];

        if (featureFlagsOverrideKeys.includes(fieldKey)) {
            return handleFeatureFlagsOverrideChange(fieldKey as keyof FeatureFlagsOverride, fieldValue);
        }
    };

    const colorRadioValues = ['red', 'magenta', 'blue', 'aqua', 'green', 'orange', 'brown', 'black', 'purple', 'yellow'];

    return (
        <Form
            id="add-project-form"
            data-id="add-project-form"
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            onValuesChange={onValuesChange}
        >
            <FormItem
                label="Project name"
                name="name"
                rules={[{ required: true, message: 'Please input project name!', whitespace: true }]}
            >
                <Input size="large" />
            </FormItem>
            <FormItem
                label="Color"
                initialValue={'blue'}
                name="color"
            >
                <Radio.Group className="color-radio-group" buttonStyle="solid">
                    {colorRadioValues.map(r => (
                        <Radio.Button key={r} value={r} className={r}>
                            <span className="color-radio" />
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </FormItem>
            <div className="feature-flag-import-export-container">
                <Upload name="file" maxCount={1} beforeUpload={beforeFeatureFlagsUpload} onRemove={resetFeatureFlags}>
                    <span className="feature-flag-import-export-button">
                        <i className="alpha-icon xs arrow-down" />
                        <span>Import feature flags</span>
                    </span>
                </Upload>
            </div>
            <FeatureFlagsRenderer
                featureFlagsOverride={featureFlagsOverride}
                setFeatureFlagsOverride={setFeatureFlagsOverride}
            />
            {/* <FormItem {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" disabled={lockSubmit}>Create</Button>
            </FormItem> */}
        </Form>);
};

export default AddProjectForm;